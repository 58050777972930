import React, { useRef } from "react";
import PrimaryButtons from "../Components/PrimaryButtons";
import ApplyPosition from "../Components/JobDetails/ApplyPosition";
import { Button } from "@nextui-org/react";

function JobDetails() {
  const targetRef = useRef(null);

  const scrollToDiv = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="">
      <div className=" px-[20px] py-[80px] mb-[-4px] mt-[1px] xl:px-32 2xl:px-60 3xl:px-[372px] w-full">
        <div className="">
          <div className="flex flex-col">
            <div className="flex items-start justify-between pb-[60px] mb-[-4px]">
              <p className=" uppercase section-title flex w-1/2 items-center my-[-7px] text-mainB ">
                Senior Risk Advisor
              </p>
              <Button
                className={`animatedDownButton buttons h-[54px]`}
                onPress={scrollToDiv}
                style={{ opacity: 100 }}
              >
                <span className=""> Apply Now </span>
              </Button>
            </div>
            <h1 className="container-title  uppercase lg:w-[718px] pb-[16px]">
              Job Summary
            </h1>
            <p className="pb-[16px] mt-[-6px] body-text lg:w-[626px]">
              Anticipates and interprets developments within supervision,
              legislations and regulations. Develops strategies, structures and
              processes to clients so they develop their business.
            </p>
            <h1 className="body-text font-bold lg:w-[718px] pb-[20px] mt-[-5px]">
              Essential Duties & Responsibilities
            </h1>
            <div className="pb-[9px] mt-[-9px] body-text lg:w-[626px] ml-[18px]">
              <ul className="list-disc list-outside">
                <li>
                  Responds to specific gaps in financial and regulatory risks
                  and uses data and technology to make programs smarter and more
                  efficient
                </li>
                <li>
                  Oversees financial risk and prudential reporting for the
                  client.
                </li>
                <li>
                  Oversees capital and liquidity forecasting and stress testing.
                </li>
                <li>Oversees key regulatory returns and disclosures.</li>
                <li>
                  Supports strategic initiatives and review independent models.
                </li>
                <li>
                  Coordinates review of key models and provides oversight of
                  control testing.
                </li>
                <li>
                  Stays updated on accounting and regulatory changes and ensures
                  compliance.
                </li>
                <li>
                  Develops business models and reviews financial and strategic
                  plans.
                </li>
              </ul>
            </div>
            <h1 className="container-title  uppercase lg:w-[718px] pb-[20px]">
              <br />
              ORGANIZATIONAL RELATIONSHIPS
            </h1>
            <p className="pb-[15px] mt-[-8px] body-text lg:w-[626px]">
              Reports to the Managing Partner and the Advisory Manager
            </p>
            <h1 className="container-title  lg:w-[718px] pb-[17px] mt-[-6px]">
              <br />
              REQUIREMENTS, QUALIFICATIONS & SKILLS
            </h1>
            <div className="pb-[20px] mt-[-6px] body-text lg:w-[626px] ml-[18px]">
              <ul className="list-disc list-outside">
                <li>Consulting and compliance experience is needed.</li>
                <li>Has, or has the ability to develop, leadership skills</li>
                <li>Coaching the work team to support their development.</li>
                <li>
                  Excellent communication, time management and organizational
                  skills.
                </li>
                <li>
                  Logical thinker with attention to detail and problem-solving
                  abilities.
                </li>
                <li>
                  Calm under pressure and able to engage constructively with
                  stakeholders.
                </li>
                <li>Self-starting attitude with attention to details.</li>
                <li>Ability to multi-task with different clients.</li>
                <li>Interest in the sector.</li>
              </ul>
            </div>
            <h1 className="container-title  lg:w-[718px] pb-[18px] mt-[-10px]">
              <br />
              EDUCATION & CERTIFICATION
            </h1>
            <div className="pb-[9px] mt-[-6px] body-text lg:w-[626px] ml-[18px]">
              <ul className="list-disc list-outside">
                <li>
                  Masters of Business Administration (MBA) in Finance, Strategic
                  Management, Accounting, Economics or related field or
                  equivalent experience and relevant accountancy qualification.
                </li>
                <li>
                  CPA, ACCA or similar is required. IFRS diploma is preferred.
                </li>
                <li>Project Management Certification is a plus.</li>
              </ul>
            </div>
            <h1 className="container-title  uppercase lg:w-[718px] pb-[20px]">
              <br />
              WORK ENVIRONMENT
            </h1>
            <p className="mt-[-10px] body-text lg:w-[626px]">
              <ul className="list-disc list-outside">
                <li>
                  The job is performed indoors and outdoors in a traditional
                  office setting.
                </li>
                <li>
                  Activities include extended periods of sitting and extensive
                  work at a computer monitor and/or calculator.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <div ref={targetRef}>
        <ApplyPosition />
      </div>
    </div>
  );
}

export default JobDetails;
