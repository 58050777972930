import React from "react";
import StandardCard from "./StandardCard";

function Standards() {
  const data = [
    {
      title: "Excellence",
      body: "Setting new standards, pushing boundaries, striving for improvement.",
      titleClass: "text-mainB ml-[-3px]",
      className: " ",
    },
    {
      title: "Precision",
      body: "Importance of meticulousness in building trust and reliability.",
      titleClass: "text-mainG ml-[-3px]",
      className: " ml-[-12px] ",
    },
    {
      title: "Reliability",
      body: "Being a dependable and trustworthy partner to our clients.",
      titleClass: "text-secondG ml-[-3px]",
      className: " ml-[-53px]",
    },
  ];
  return (
    <div className=" px-[20px] max-lg:pr-[20px] max-lg:pl-[20px] py-[80px] xl:px-32 2xl:px-60 3xl:px-[372px]">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px] lg:grid-cols-3 mt-[-7px] mb-[-5px]">
        {data.map((standard) => {
          return (
            <StandardCard
              title={standard.title}
              body={standard.body}
              titleClass={standard.titleClass}
              className={standard.className}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Standards;
