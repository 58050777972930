import { Input, Radio, RadioGroup } from "@nextui-org/react";
import React from "react";
import PrimaryButtons from "../PrimaryButtons";

function EmployeeTraining({ setActiveIndex }) {
  function handleSubmit() {
    setActiveIndex(3);
  }

  const [selected, setSelected] = React.useState("");

  return (
    <div>
      <div className="section-title">Employee Training and Development</div>
      <form onSubmit={handleSubmit}>
        <div className="grid max-sm:grid-cols-1  pt-[55px] mt-[-2px] gap-y-[20px] gap-x-[20px]">
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="string"
              label="Provide the number of employees in your firm who hold professional certifications, with a list and elaboration on the specific certifications they hold"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className="col-span-1 max-sm:col-span-2 flex w-full justify-between items-center">
            <div className="w-full container-title max-lg:!text-[12pt] !text-[14pt]">
              Do you offer any training/development program for your staff?
            </div>
            <div className="w-full">
              <RadioGroup
                orientation="horizontal"
                isRequired
                value={selected}
                onValueChange={setSelected}
              >
                <Radio value="Yes" className="mr-[40px]">
                  Yes
                </Radio>
                <Radio value="No">No</Radio>
              </RadioGroup>
            </div>
          </div>
          {selected == "Yes" && (
            <div className=" col-span-1 max-sm:col-span-2 ">
              <Input
                isClearable
                isRequired
                required
                radius={"none"}
                variant="bordered"
                type="string"
                label="If yes, what types of training and development programs do you offer to your employees?"
                labelPlacement="outside-left"
                className="h-[60px] w-full"
                classNames={{
                  inputWrapper: "h-[60px] shadow-none border-1",
                  base: "w-full",
                  mainWrapper: "w-full",
                  label:
                    "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
                }}
              />
            </div>
          )}
          {selected == "Yes" && (
            <div className=" col-span-1 max-sm:col-span-2 ">
              <Input
                isClearable
                isRequired
                required
                radius={"none"}
                variant="bordered"
                type="string"
                label="Provide an overview of these programs in 2024:"
                labelPlacement="outside-left"
                className="h-[60px] w-full"
                classNames={{
                  inputWrapper: "h-[60px] shadow-none border-1",
                  base: "w-full",
                  mainWrapper: "w-full",
                  label:
                    "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
                }}
              />
            </div>
          )}
          <div className="flex justify-end">
            <PrimaryButtons buttontype={"submit"} label={"Next"} />
          </div>
        </div>
      </form>
    </div>
  );
}

export default EmployeeTraining;
