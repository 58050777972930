import React from "react";
import Title from "../Title";
import IndustryCard from "../IndustryCards";
import FinancialPIC from "../../assets/Industries/Financial.png";
import ConsumerPIC from "../../assets/Industries/Consumer.png";
import HospitalityPIC from "../../assets/Industries/Hospitality.png";
import HealthPIC from "../../assets/Industries/HealthCare.png";
import EnergyPIC from "../../assets/Industries/Energy.png";
import TechnologyPIC from "../../assets/Industries/Technology.png";
import IndustrialPIC from "../../assets/Industries/Industrial.png";
import TransportPIC from "../../assets/Industries/Transport.png";
import PublicPIC from "../../assets/Industries/Public.png";
import Card from "../Card";
import { useNavigate } from "react-router-dom";

function IndustriesCategories() {
  const navigate = useNavigate();
  const cards = [
    {
      title: "FINANCIAL SERVICES",
      description:
        "PA helps financial institutions optimize processes and enhance customer experiences.",
      image: FinancialPIC,
    },
    {
      title: "CONSUMER AND RETAIL",
      description:
        "Our custom solutions for Consumer and Retail sectors keep your business ahead.",
      image: ConsumerPIC,
    },
    {
      title: "HOSPITALITY AND LEISURE",
      description:
        "PA sets new benchmarks in hospitality satisfaction and operations.",
      image: HospitalityPIC,
    },
    {
      title: "PHARMACEUTICALS AND HEALTHCARE",
      description: "Innovating Healthcare and Pharmaceutical Solutions",
      image: HealthPIC,
    },
    {
      title: "Energy and Environment",
      description:
        "PA transforms Energy and Environment with tailored sustainability solutions.",
      image: EnergyPIC,
    },
    {
      title: "Technology and Telecommunications",
      description: "PA Empowering Digital Transformation",
      image: TechnologyPIC,
    },
    {
      title: "Industrial",
      description:
        "PA Driving Resilience and Innovation in a Transforming Industry",
      image: IndustrialPIC,
    },
    {
      title: "Transport and Logistics",
      description: "PA Guiding Your Path to Efficiency and Growth",
      image: TransportPIC,
    },
    {
      title: "Public and Social Sector",
      description:
        "PA Transformative Solutions for the Public and Social Sector",
      image: PublicPIC,
    },
  ];
  function navigateToIndustriesCategory() {
    navigate("/IndustriesCategory");
  }
  return (
    <div className=" min-h-screen bg-mainB max-lg:pr-[20px] max-lg:pl-[20px] px-[20px] py-[60px] xl:py-[80px] xl:px-32 2xl:px-60 3xl:px-[372px]">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[20px] gap-y-[39px] ">
        {cards.map((card, index) => (
          <Card
            details={card}
            titleIsWhite={true}
            key={index}
            descriptionIsWhite={true}
            action={navigateToIndustriesCategory}
          />
        ))}
      </div>
    </div>
  );
}

export default IndustriesCategories;
