import React from "react";
import Card from "../Card";
import AIPic from "../../assets/Insights/AI-Insight.png";
import ESGPic from "../../assets/Insights/ESG-Insight.png";
import RISKPic from "../../assets/Insights/RISK-Insight.png";
import Explore from "../Explore";
import { useNavigate } from "react-router-dom";

function InsightFromDetails() {
  let navigate = useNavigate();
  const cards = [
    {
      title: "Tech, AI STEM Education AI",
      description: "Empowering Students for the Future..",
      image: AIPic,
    },
    {
      title: "Audit AI and Materiality",
      description: "AI's Role in Precision, Risk Detection.",
      image: ESGPic,
    }
  ];
  function navigateToInsights() {
    navigate("/Insights");
  }
  function navigateToInsightCategory() {
    
  }
  return (
    <div className="px-[10px] pt-[80px] lg:px-5 xl:px-32 2xl:px-60 3xl:px-[372px]">
      <div className="flex flex-col justify-center items-center w-full  lg:mt-[-1px]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px] w-full">
          {cards.map((card, index) => (
            <div className="text-white">
              <Card
                smallVersion={true}
                titleIsWhite={true}
                details={card}
                key={index}
                action={navigateToInsightCategory}
              />
            </div>
          ))}
        </div>
        <div className="pb-[80px] pt-[60px]">
          <Explore buttonLabel={"View Insights"} action={navigateToInsights} />
        </div>
      </div>
    </div>
  );
}

export default InsightFromDetails;
