import React from "react";
import BorderedArrow from "./BorderedArrow";
import { Divider } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
function ServicesInfo({ details, key }) {
  let navigate = useNavigate();
  function goTo() {
    navigate("/ServiceDetails");
  }
  return (
    <div>
      <div className="flex gap-4 lg:items-center lg:gap-[101px] text-sm">
        <div className="flex gap-[20px] items-center flex-1 w-full">
          <img
            src={details.image}
            alt="ServicesInfoImg"
            className="h-[60px] w-[60px] object-cover"
          />
          <div className="flex flex-col gap-[10px] text-mainB lg:min-w-[350px]">
            <h1 className="font-bold container-title">{details.title} </h1>
            <p className=" overflow-hidden body-text ml-[-1px]">
              {details.subtitle}{" "}
            </p>
          </div>
        </div>
        <div className=" flex items-center justify-center gap-[40px] body-text">
          <div className="flex flex-col gap-[10px]">
            <div></div>
            <p className="max-lg:hidden mb-[-2px]">{details.description}</p>
          </div>

          <div className=" flex justify-center items-center" onClick={goTo}>
            <BorderedArrow />
          </div>
        </div>
      </div>
      <Divider className="my-[20px]" />
    </div>
  );
}

export default ServicesInfo;
