import React from "react";
import { ReactComponent as Pointer } from "../../assets/SVG/pointer.svg";
import {
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  MapIcon,
  PhoneIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";

function CountryCard({
  country,
  address,
  phoneNb = [],
  mobile = [],
  fax = [],
  email,
}) {
  return (
    <div className="relative w-full pb-full border h-[260px] flex flex-col justify-between">
      <div className="absolute top-0 left-0 w-full h-full">
        <div className="text-mainB bg-cultured flex flex-col p-5 h-full">
          <div className="flex flex-col">
            <div className="text-secondG flex gap-2 ml-[-3px] container-title">
              <Pointer />
              {country}
            </div>
            <div className="mt-5">
              <p className="body-text flex items-center gap-5 h-[40px]">
                <MapIcon className="min-w-5 min-h-5 max-w-5 max-h-5 text-secondG" />
                Address: {address}
              </p>
              {phoneNb.length > 0 && (
                <p className="body-text mt-[7px] flex items-center gap-5">
                  <PhoneIcon className="w-5 h-5 text-secondG" />
                  Phone Number: {phoneNb.join(", ")}
                </p>
              )}
              {mobile.length > 0 && (
                <p className="body-text mt-[19px] flex items-center gap-5">
                  <DevicePhoneMobileIcon className="w-5 h-5 text-secondG" />
                  Mobile: {mobile.join(", ")}
                </p>
              )}
              {fax.length > 0 && (
                <p className="body-text mt-[15px] flex items-center gap-5">
                  <PrinterIcon className="w-5 h-5 text-secondG" />
                  Fax: {fax.join(", ")}
                </p>
              )}
              {email && (
                <p className="body-text mt-[16px] flex items-center gap-5">
                  <EnvelopeIcon className="w-5 h-5 text-secondG" />
                  Email: {email}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CountryCard;
