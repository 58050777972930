import React, { useEffect, useState } from "react";
import { Menubar } from "primereact/menubar";
import { Image } from "@nextui-org/react";
import { Navbar } from "@nextui-org/react";

import { ReactComponent as MessageIcon } from "../assets/SVG/message.svg";
import { ReactComponent as PersonIcon } from "../assets/SVG/person.svg";
import { ReactComponent as WorldIcon } from "../assets/SVG/world.svg";
import { ReactComponent as SearchIcon } from "../assets/SVG/search.svg";
import { ReactComponent as PaLogo } from "../Components/PaLogo.svg";
import "../Css/MenuBar.css";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import MegaMenu from "./MegaMenu";
function NavBar() {
  function Navigation() {
    const location = useLocation();

    // Your items array
    const items = [
      {
        label: "Insights",
        url: "/Insights",
        currentActive: true,
        template: itemRenderer,
      },
      {
        label: "Services",
        url: "/Services",
        currentActive: false,
        template: itemRenderer,
      },
      {
        label: "Industries",
        currentActive: false,
        url: "/Industries",
        template: itemRenderer,
      },
      {
        label: "Careers",
        currentActive: false,
        url: "/Careers",
        template: itemRenderer,
      },
      {
        label: "About Us",
        url: "/AboutUs",
        currentActive: false,
        template: itemRenderer,
      },
    ];

    // Function to check if a given URL matches the current URL
    const isCurrentUrl = (url) => {
      return location.pathname === url;
    };

    // Update currentActive property dynamically based on current URL
    const updatedItems = items.map((item) => {
      if (item.url) {
        return {
          ...item,
          currentActive: isCurrentUrl(item.url),
        };
      } else {
        return item;
      }
    });
    return updatedItems;
  }
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 996);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 996);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const itemRenderer = (item) => (
    <Link to={item.url}>
      <div className="w-full flex align-items-center group p-menuitem-link navBarTitle max-lg:pl-12">
        <span className={item.icon} />
        <span
          className={`w-full text-sm navBarTitle  tm-link text-mainB center ${
            item.currentActive ? " active " : ""
          }`}
        >
          {item.label}
        </span>
      </div>
    </Link>
  );
  const subMenuRenderer = (item) => (
    <div className="flex items-center justify-between p-2 text-mainB cursor-pointer ">
      <div className="flex flex-col w-full navBarTitle max-lg:pl-6">
        <span
          className={`navBarTitle  ${
            item.currentActive ? "text-secondG" : "text-mainB"
          }`}
        >
          {item.label}
        </span>
        {item.description && (
          <span className="text-xs mt-1 w-full">{item.description}</span>
        )}
      </div>
      <div>
        <ChevronRightIcon className="h-4 w-4" />
      </div>
    </div>
  );

  const start = (
    <Link to={"/"} className="">
      <div className="flex ">
        <PaLogo />
      </div>
    </Link>
  );
  const end = (
    <div className="w-full justify-end">
      <div className="flex gap-[39px] items-center justify-center flex-1 ">
        <Link to={"/Connect"}>
          {" "}
          <MessageIcon className=" w-5 max-[960px]:hidden hover:text-secondG hover:cursor-pointer" />
        </Link>
        <PersonIcon className=" w-5 hover:text-secondG hover:cursor-pointer " />
        <WorldIcon className=" w-5 max-[960px]:hidden hover:text-secondG hover:cursor-pointer" />
        <SearchIcon className="w-5 hover:text-secondG hover:cursor-pointer" />
      </div>
    </div>
  );
  return (
    <Navbar shouldHideOnScroll className="w-full navbarClass z-20 inset-0 ">
   
        <Menubar
          model={Navigation()}
          start={start}
          end={end}
          menuIcon={
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75 3.66663H19.25V5.49996H2.75V3.66663ZM2.75 10.0833H13.75V11.9166H2.75V10.0833ZM2.75 16.5H19.25V18.3333H2.75V16.5Z"
                fill="#002C54"
              />
            </svg>
          }
          className="z-50 flex items-center lg:hidden justify-between h-[80px] px-[40px] xl:px-44 2xl:px-60 3xl:px-[421px] w-full bg-white "
        />
 
        <MegaMenu />
 
    </Navbar>
  );
}

export default NavBar;
