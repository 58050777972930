import React from "react";
import DetailsInfo from "../DetailsInfo";
import YourJourneyImage from "../../assets/Careers/YourJourney.png";

function YourJourney() {
  const firstData = {
    image: YourJourneyImage,
    title: "Your Journey of Growth Starts Here",
    subtitle: "Ready for a Journey with a Global Network?",
    description: `Explore our openings at PA Global to align your skills with our mission of excellence. Join our global network where trust, integrity, and collaboration drive success. Surpass your potential with PA Global.`,
  };
  const buttonData = {
    label: "Contact Us",
    className: "mt-[60px]"
  };
  return (
    <div className="">
      <DetailsInfo data={firstData} haveButton={true} buttonData={buttonData} />
    </div>
  );
}

export default YourJourney;
