import React from "react";
import HeaderImage from "../../assets/HeadersBackground/IndustriesCategoriesHeader.png";
import Header from "../Header";
function IndustriesPageHeader() {
  const title = "Financial Services";
  const description = ` In a rapidly evolving landscape driven by disruptive technologies,
              PA leverages global expertise and sector-specific knowledge to
              navigate complexities, foresee needs, and drive sustainable growth
              for clients amidst opportunities & challenges.`;

  return (
    <div>
      <Header title={title} image={HeaderImage} description={description} />
    </div>
  );
}
export default IndustriesPageHeader;
