import React, { useRef, useState } from "react";
import {
  Accordion,
  AccordionItem,
  CheckboxGroup,
  Textarea,
} from "@nextui-org/react";
import BorderedArrow from "../BorderedArrow";
import CustomTextField from "../CustomTextField";
import PrimaryButtons from "../PrimaryButtons";
import { Checkbox, Link, User, Chip, cn } from "@nextui-org/react";
import { TextField } from "@mui/material";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import BecomeAMemberFirm from "./BecomeAMemberFirm";
import ContactUs from "./ContactUs";

function ConnectForm() {
  const [isSelected, setIsSelected] = React.useState(false);
  const fileInputRef = useRef(null);
  const [fileUploaded, setFileUploaded] = useState(null);

  const handleDivClick = () => {
    // When the div is clicked, trigger the file input click
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    // Get the selected file
    const file = event.target.files[0];
    setFileUploaded(file);
  };
  return (
    <div className="px-[10px] pt-[80px] lg:px-5 mt-[-7px] xl:px-32 2xl:px-60 3xl:px-[372px]">
      <div className="">
        <BecomeAMemberFirm />
        <ContactUs />
      </div>
    </div>
  );
}

export default ConnectForm;
