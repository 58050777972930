import React from "react";

function Title({ title, className }) {
  return (
    <div className={`text-secondG uppercase section-title ${className}`}>
      {title}
    </div>
  );
}

export default Title;
