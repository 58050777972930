import React from "react";

function ValueCard({
  IconComponent,
  text,
  className = " ",
  contentClassName = " ",
  iconClassName = " ",
}) {
  return (
    <div
      className={` w-full h-full text-mainB bg-cultured flex flex-col ${className}`}
    >
      <div className={` flex flex-col items-left p-5 ${contentClassName}`}>
        <div className={` w-[60px] h-[60px] ${iconClassName} `}>
          <IconComponent className="" />
        </div>
        <ul className="mt-[10px] body-text list-disc pl-5 ml-[-4px] mb-[-4px] text-left ">
          {text.map((list) => {
            return <li>{list}</li>;
          })}
        </ul>
      </div>
    </div>
  );
}

export default ValueCard;
