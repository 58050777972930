import React from "react";
import Card from "../Card";
import AIPic from "../../assets/Industries/Rectangle 5 (3).png";
import ESGPic from "../../assets/Industries/Insights image 1.png";
import RISKPic from "../../assets/Industries/Rectangle 29.png";
import Explore from "../Explore";
import { useNavigate } from "react-router-dom";

function InsightFromDetails() {
  let navigate = useNavigate();
  const cards = [
    {
      title: "Technology and Telecommunications",
      description: "PA Empowering Digital Transformation",
      image: RISKPic,
    },
    {
      title: "Energy and Environment",
      description:
        "PA transforms Energy and Environment with tailored sustainability solutions.",
      image: ESGPic,
    },
  ];
  function navigateToIndustries() {
    navigate("/Industries");
  }
  function navigateToInsightCategory() {
    navigate("/IndustriesCategory");
  }
  return (
    <div className="px-[10px] pt-[80px] lg:px-5 xl:px-32 2xl:px-60 3xl:px-[372px]">
      <div className="flex flex-col justify-center items-center  w-full lg:mt-[-1px]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px]  w-full">
          {cards.map((card, index) => (
            <div className="text-white col-span-1">
              <Card
                smallVersion={true}
                titleIsWhite={true}
                details={card}
                key={index}
                action={navigateToInsightCategory}
              />
            </div>
          ))}
        </div>
        <div className="pb-[80px] pt-[60px]">
          <Explore
            buttonLabel={"View Industries"}
            action={navigateToIndustries}
          />
        </div>
      </div>
    </div>
  );
}

export default InsightFromDetails;
