import React from "react";
import BorderedArrow from "./BorderedArrow";
import PrimaryButtons from "./PrimaryButtons";
import { useNavigate } from "react-router-dom";
function IndustryCard({ details, whiteVersion }) {
  let navigate = useNavigate()
  function goTo() {
    navigate("/IndustriesCategory");
  }
  return (
    <div className=" mt-[-2px]">
      <h1
        className={` container-title uppercase mb-[-3px] max-lg:pt-[2px] pb-[20px] overflow-hidden text-ellipsis whitespace-nowrap  ${
          whiteVersion ? "text-mainB" : "text-white"
        } `}
      >
        {details.title}
      </h1>
      <div className="group relative">
        <img
          src={details.image}
          alt="artificial intelligence insight"
          className="h-full w-full object-contain"
        />
        <div className="top-0 absolute h-full w-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in">
          <div className="absolute flex items-center justify-center h-full w-full opacity-100 z-10">
            <PrimaryButtons
              label={"Read more"}
              className={"hover:opacity-100"}
              action={goTo}
            />
          </div>
          <div className="bg-mainB opacity-70 h-full" />
        </div>
      </div>
      <div className="mt-[20px] flex items-center  gap-[10px]">
        <BorderedArrow action={goTo}/>
        <div
          className={`body-text ${
            whiteVersion ? "text-mainB" : "text-white"
          }`}
        >
          {details.description}
        </div>
      </div>
    </div>
  );
}

export default IndustryCard;
