import React from "react";
import PrimaryButtons from "../PrimaryButtons";
import { TextField } from "@mui/material";
import CustomTextField from "../CustomTextField";

function Subscribe() {
  return (
    <div className="bg-[#F3F7F9]  px-[20px] max-lg:pr-[20px] max-lg:pl-[20px] py-[80px] xl:px-32 2xl:px-60 3xl:px-[372px]">
      <h1 className="max-sm:max-w-[400px] uppercase text-mainB section-title mt-[-7px] mb-[-7px]">
      subscribe to our newsletter
      </h1>

      <div className="grid  pt-[60px] mt-[-2px] gap-y-[22px] gap-x-[20px] ">
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField
            label="First Name"
            id="fullWidth"
            variant="outlined"
          />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField
            label="Last Name"
            id="fullWidth"
            variant="outlined"
          />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField label="Company" id="fullWidth" variant="outlined" />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField
            label="Job Title"
            id="fullWidth"
            variant="outlined"
          />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField label="Email" id="fullWidth" variant="outlined" />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField
            label="Phone Number"
            id="fullWidth"
            variant="outlined"
          />{" "}
        </div>
        <div className="col-span-2 mt-[-1px]">
          <TextField
            fullWidth
            sx={{
              "& .MuiInputLabel-root": {
                color: "#002C54",
                fontFamily: "NotoSansReg",

              },
              "& label": {
                paddingLeft: (theme) => theme.spacing(1),
                color: "#002C54",
                lineHeight: "20.52px",
              },
              "& input": {
                paddingLeft: (theme) => theme.spacing(1.5),
                color: "#002C54"
              },
              "& fieldset": {
                paddingLeft: (theme) => theme.spacing(1.5),
                borderColor: "#DEE1E3", // Default border color
              },
              "& .MuiOutlinedInput-root": {
                height: "90px",
                display: "flex",
                alignItems: "start",
                backgroundColor: "white",
                "& fieldset": {
                  transition: "border-color 0.3s",
                  borderRadius: "0px",
                },
                "&:hover fieldset": {
                  borderColor: "#DEE1E3", // Set hover border color to match default
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#DEE1E3",
                },
              },
            }}
            label="Message"
            id="fullWidth"
            variant="outlined"
          />
          {/* <Textarea
            fullWidth
            label="Message"
            labelPLace
            id="fullWidth"
            variant="outlined"
            classNames={{
              inputWrapper:
                "bg-white rounded-none border border-[#DEE1E3] border-[0.5pt] shadow-none hover:bg-white inputWrapperTextArea",
            }}
          /> */}
        </div>
      </div>
      <div className="mt-[60px]">
        <PrimaryButtons label="Submit" />
      </div>
    </div>
  );
}

export default Subscribe;
