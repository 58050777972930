import React from "react";
import Title from "../Title";
import IndustryCard from "../IndustryCards";
import FinancialPIC from "../../assets/Industries/Financial.png";
import ConsumerPIC from "../../assets/Industries/Consumer.png";
import HospitalityPIC from "../../assets/Industries/Hospitality.png";
import HealthPIC from "../../assets/Industries/HealthCare.png";
import EnergyPIC from "../../assets/Industries/Energy.png";
import TechnologyPIC from "../../assets/Industries/Technology.png";
import IndustrialPIC from "../../assets/Industries/Industrial.png";
import TransportPIC from "../../assets/Industries/Transport.png";
import PublicPIC from "../../assets/Industries/Public.png";
import { Input, Select, SelectItem } from "@nextui-org/react";
import IndustriesInfo from "../IndustriesPageSections/IndustriesInfo";
import { Link } from "react-router-dom";
import AllInfo from "../AllInsightsInfo";

function AllIndustries() {
  const cards = [
    {
      title: "FINANCIAL SERVICES",
      subtitle: "PA helps financial institutions optimize processes ",
      image: FinancialPIC,
    },
    {
      title: "CONSUMER AND RETAIL",
      subtitle:
        "Our custom solutions for Consumer and Retail sectors keep your business ahead.",
      image: ConsumerPIC,
    },
    {
      title: "HOSPITALITY AND LEISURE",
      subtitle:
        "PA sets new benchmarks in hospitality satisfaction and operations.",
      image: HospitalityPIC,
    },
    {
      title: "PHARMACEUTICALS AND HEALTHCARE",
      subtitle: "PA helps financial institutions optimize processes ",
      image: HealthPIC,
    },
    {
      title: "Energy and Environment",
      subtitle:
        "PA transforms Energy and Environment with tailored sustainability solutions.",
      image: EnergyPIC,
    },
    {
      title: "Technology and Telecommunications",
      subtitle: "PA helps financial institutions optimize processes ",
      image: TechnologyPIC,
    },
    {
      title: "Industrial",
      subtitle: "PA helps financial institutions optimize processes ",
      image: IndustrialPIC,
    },
    {
      title: "Transport and Logistics",
      subtitle: "PA helps financial institutions optimize processes ",
      image: TransportPIC,
    },
    {
      title: "Public and Social Sector",
      subtitle: "PA helps financial institutions optimize processes ",
      image: PublicPIC,
    },
  ];

  const services = [
    { key: 1, label: "Service 1" },
    { key: 2, label: "Service 2" },
  ];

  return (
    <div className="min-h-screen px-[20px] py-16 xl:py-[80px] xl:px-32 2xl:px-60 3xl:px-[372px]">
      <div className="justify-start items-center w-full my-[-7px]">
        <Title title={"all Industries"} className={"text-start mt-[-1px]"} />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-[20px] py-[40px] h-full">
        <Select
          label="Select Service"
          variant="bordered"
          className="max-w-xs rounded-none"
          classNames={{
            trigger: "rounded-none",
            popoverContent: "rounded-none",
            base: "rounded-none",
            label: "rounded-none",
            innerWrapper: "rounded-none",
            trigger: "rounded-none",
            mainWrapper: "rounded-none",
            selectorIcon: "rounded-none",
            value: "rounded-none",
            listboxWrapper: "rounded-none",
            listbox: "rounded-none",
            popoverContent: "rounded-none",
            helperWrapper: "rounded-none",
            description: "rounded-none",
            errorMessage: "rounded-none",
          }}
        >
          {services.map((service) => (
            <SelectItem key={service.key} className="rounded-none">
              {service.label}
            </SelectItem>
          ))}
        </Select>
        <Select
          label="Select Industry"
          variant="bordered"
          className="max-w-xs rounded-none"
          classNames={{
            trigger: "rounded-none",
            popoverContent: "rounded-none",
          }}
        >
          {services.map((service) => (
            <SelectItem key={service.key} className="rounded-none">
              {service.label}
            </SelectItem>
          ))}
        </Select>
        <Select
          label="Sort By"
          variant="bordered"
          className="max-w-xs rounded-none"
          classNames={{
            trigger: "rounded-none",
            popoverContent: "rounded-none",
          }}
        >
          {services.map((service) => (
            <SelectItem key={service.key} className="rounded-none">
              {service.label}
            </SelectItem>
          ))}
        </Select>
        <div className="h-full">
          <Input
            type="search"
            placeholder="Search"
            variant="bordered"
            className="max-w-xs h-full"
            endContent={
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.1442 10.9041L12.6028 11.4823L11.7115 10.6728C12.6361 9.54933 13.1942 8.11198 13.1942 6.54245C13.1942 2.93254 10.2455 0 6.59711 0C2.95704 0 0 2.92428 0 6.54245C0 10.1524 2.94871 13.0849 6.59711 13.0849C8.37133 13.0849 9.98729 12.391 11.1701 11.2593L12.0614 12.0688L11.5199 12.6471L17.384 18L19 16.257L13.1442 10.9041ZM6.59711 12.3001C3.39851 12.3001 0.79965 9.72281 0.79965 6.55071C0.79965 3.37861 3.39851 0.801285 6.59711 0.801285C9.7957 0.801285 12.3946 3.37861 12.3946 6.55071C12.3946 9.72281 9.7957 12.3001 6.59711 12.3001ZM17.3341 16.8848L12.6445 12.6058L13.1859 12.0275L17.8755 16.3066L17.3341 16.8848Z"
                  fill="#A5A5A5"
                />
              </svg>
            }
            classNames={{
              inputWrapper: "rounded-none h-full",
              mainWrapper: "h-full",
            }}
          />
        </div>
      </div>
      <div className="mt-[37px] grid grid-cols-1 lg:grid-cols-2 gap-[20px]">
        {cards.map((item, index) => (
          <AllInfo details={item} key={index} blueText={true} />
        ))}
      </div>
    </div>
  );
}

export default AllIndustries;
