import React from "react";
import Header from "../Components/WelcomeSections/Header";
import InsightsSection from "../Components/WelcomeSections/InsightsSection";
import Services from "../Components/WelcomeSections/Services";
import Industries from "../Components/WelcomeSections/Industries";
import Subscribe from "../Components/WelcomeSections/Subscribe";

function Welcome() {
  return (
    <div>
      <Header />
      <InsightsSection />
      <Services />
      <Industries />
      <Subscribe />
    </div>
  );
}

export default Welcome;
