import React from "react";
import HeaderServices from "../Components/ServiceCategorySections/HeaderServices";
import ServicesCategories from "../Components/ServiceCategorySections/ServicesCategories";
import RFPForm from "../Components/RFPForm";

function ServicesCategory() {
  return (
    <div>
      <HeaderServices />
      <ServicesCategories />
    </div>
  );
}

export default ServicesCategory;
