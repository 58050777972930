import { Input, Radio, RadioGroup } from "@nextui-org/react";
import React from "react";
import PrimaryButtons from "../PrimaryButtons";

function JurisdictionServices({ setActiveIndex }) {
  function handleSubmit() {
    setActiveIndex(2);
  }
  const [selected, setSelected] = React.useState("");

  return (
    <div>
      <div className="section-title">Outside of Jurisdiction Services</div>
      <form onSubmit={handleSubmit}>
        <div className="grid max-sm:grid-cols-1  pt-[55px] mt-[-2px] gap-y-[20px] gap-x-[20px]">
          <div className="col-span-1 max-sm:col-span-2 flex w-full justify-between items-center">
            <div className="w-full container-title max-lg:!text-[12pt] !text-[14pt]">
              Do you perform services outside your jurisdictions' entities?
            </div>
            <div className="w-full">
              <RadioGroup
                orientation="horizontal"
                isRequired
                value={selected}
                onValueChange={setSelected}
              >
                <Radio value="Yes" className="mr-[40px]">
                  Yes
                </Radio>
                <Radio value="No">No</Radio>
              </RadioGroup>
            </div>
          </div>
          {selected == "Yes" && (
            <div className=" col-span-1 max-sm:col-span-2 ">
              <Input
                isClearable
                isRequired
                required
                radius={"none"}
                variant="bordered"
                type="string"
                label="If yes, list the types of services performed:"
                labelPlacement="outside-left"
                className="h-[60px] w-full"
                classNames={{
                  inputWrapper: "h-[60px] shadow-none border-1",
                  base: "w-full",
                  mainWrapper: "w-full",
                  label:
                    "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
                }}
              />
            </div>
          )}
          {selected == "Yes" && (
            <div className=" col-span-1 max-sm:col-span-2 ">
              <Input
                isClearable
                isRequired
                required
                radius={"none"}
                variant="bordered"
                type="string"
                label="Percentage of these external services provided:"
                labelPlacement="outside-left"
                className="h-[60px] w-full"
                classNames={{
                  inputWrapper: "h-[60px] shadow-none border-1",
                  base: "w-full",
                  mainWrapper: "w-full",
                  label:
                    "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
                }}
              />
            </div>
          )}
          <div className="flex justify-end">
            <PrimaryButtons buttontype={"submit"} label={"Next"} />
          </div>
        </div>
      </form>
    </div>
  );
}

export default JurisdictionServices;
