import React from "react";
import AboutUsHeader from "../Components/AboutUsSections/AboutUsHeader";
import Standards from "../Components/AboutUsSections/Standards";
import OurMission from "../Components/AboutUsSections/OurMission";
import DetailsInfo from "../Components/DetailsInfo";
import SuccessImage from "../assets/AboutUs/SuccessImage.png";
import Value from "../Components/AboutUsSections/Value";
import Vision from "../Components/AboutUsSections/Vision";

function AboutUs() {
  const data = {
    image: SuccessImage,
    title: "Our success",
    subtitle:
      "Since 1996, PA Global has enhanced efficiency with top professional standards and confidentiality.",
    description:
      "Our success stems from a collaborative, transparent approach. We offer assurance, business advisory, tax compliance, consulting, risk assessment, and technology services. \n \n We uphold integrity, objectivity, and independence. We contribute to national economic development, promote standards, and engage in public interest matters. Using up-to-date technologies and agile methods, we address emerging risks in a changing environment.",
  };
  return (
    <div>
      <AboutUsHeader />
      <Standards />
      <div className="bg-mainB pb-[80px]">
        <OurMission />
        <DetailsInfo data={data} whiteText={true} flipped={true} />
      </div>
      <Value />
      <Vision />
    </div>
  );
}

export default AboutUs;
