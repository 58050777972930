import React from "react";
import {
  Autocomplete,
  AutocompleteItem,
  Input,
  Select,
  SelectItem,
} from "@nextui-org/react";
import AIEDInsight from "../../assets/Insights/AIED-Insight.png";
import Title from "../Title";
import AllInfo from "../AllInsightsInfo";
import Explore from "../Explore";
import ESG from "../../assets/AllInsights/ESG_ISSB.png";
import Audit from "../../assets/AllInsights/Audit.png";
import RealTime from "../../assets/AllInsights/real-time.png";
import AuditElephant from "../../assets/AllInsights/Finance.png";
import Risk from "../../assets/AllInsights/risk.png";
import AI from "../../assets/AllInsights/AI.png";
import Agility from "../../assets/AllInsights/agility.png";
import Smart from "../../assets/AllInsights/Smart.png";
import Tax from "../../assets/AllInsights/tax.png";
import Economy from "../../assets/AllInsights/Economy.png";
import Tightrope from "../../assets/AllInsights/tightrope.png";
import Finance from "../../assets/AllInsights/Finance.png";
function AllInsights() {
  const info = [
    {
      title: "Tech, AI STEM Education AI",
      subtitle: "Empowering Students for the Future.",
      image: AIEDInsight,
    },
    {
      title: "ESG ISSB",
      subtitle: "Navigating ESG Reporting Challenges.",
      image: ESG,
    },
    {
      title: "Audit AI and Materiality",
      subtitle: "AI's Role in Precision, Risk Detection.",
      image: Audit,
    },
    {
      title: "Real Time Financial Reporting",
      subtitle: "Real-Time Financial Reporting with IFRS.",
      image: RealTime,
    },
    {
      title: "Audit Elephant In The Room",
      subtitle: "Navigating Critical Challenges.",
      image: AuditElephant,
    },
    {
      title: "Risk Tolerance",
      subtitle: "Keys to Long-Term Success.",
      image: Risk,
    },
    {
      title: "Audit Artificial Intelligence",
      subtitle: "Evolving Standards & Skills in Auditing.",
      image: AI,
    },
    {
      title: "Agility Advantage",
      subtitle: "Navigating Economic Trends.",
      image: Agility,
    },
    {
      title: "ESG SMART Goals",
      subtitle: "SMART Goals for SMEs.",
      image: Smart,
    },
    {
      title: "Tax Maze",
      subtitle: "Navigating International Taxation.",
      image: Tax,
    },
    {
      title: "Gig Economy",
      subtitle: "Navigating Financial Uncertainties.",
      image: Economy,
    },
    {
      title: "Tax Tightrope",
      subtitle: "Strategic Tax Planning.",
      image: Tightrope,
    },
    {
      title: "Behavioral Finance",
      subtitle: "Overcoming Mental Traps for Success.",
      image: Finance,
    },
  ];
  const services = [
    { key: 1, label: "Service 1" },
    { key: 2, label: "Service 2" },
  ];

  return (
    <div className="w-full h-full bg-mainB text-white px-[20px] pt-[80px] xl:px-32 2xl:px-60 3xl:px-[372px] ">
      <div className="flex max-lg:flex-col justify-between items-center gap-14 ">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-[20px] pb-[40px] w-full">
          <Select
            label="Select Service"
            variant="flat"
            className="max-w-xs rounded-none"
            classNames={{
              trigger: [
                "rounded-none text-white bg-secondG hover:bg-secondG text-white",
                "data-[hover=true]:bg-secondG",
              ],
              label: ["text-white", "group-data-[filled=true]:text-white"],
              value: "text-white group-data-[has-value=true]:text-white",
              popoverContent: "rounded-none",
            }}
          >
            {services.map((service) => (
              <SelectItem key={service.key} className="rounded-none">
                {service.label}
              </SelectItem>
            ))}
          </Select>
          <Select
            label="Select Industry"
            variant="flat"
            className="max-w-xs rounded-none"
            classNames={{
              trigger: [
                "rounded-none text-white bg-secondG hover:bg-secondG text-white",
                "data-[hover=true]:bg-secondG",
              ],
              label: ["text-white", "group-data-[filled=true]:text-white"],
              value: "text-white  group-data-[has-value=true]:text-white",
              popoverContent: "rounded-none",
            }}
          >
            {services.map((service) => (
              <SelectItem key={service.key} className="rounded-none">
                {service.label}
              </SelectItem>
            ))}
          </Select>
          <Select
            label="Sort Insight"
            variant="flat"
            className="max-w-xs rounded-none"
            classNames={{
              trigger: [
                "rounded-none text-white bg-secondG hover:bg-secondG text-white",
                "data-[hover=true]:bg-secondG",
              ],
              label: ["text-white", "group-data-[filled=true]:text-white"],
              value: "text-white  group-data-[has-value=true]:text-white",
              popoverContent: "rounded-none",
            }}
          >
            {services.map((service) => (
              <SelectItem key={service.key} className="rounded-none">
                {service.label}
              </SelectItem>
            ))}
          </Select>
          <Select
            label="Sort Order"
            variant="flat"
            className="max-w-xs rounded-none"
            classNames={{
              trigger: [
                "rounded-none text-white bg-secondG hover:bg-secondG text-white",
                "data-[hover=true]:bg-secondG",
              ],
              label: ["text-white", "group-data-[filled=true]:text-white"],
              value: "text-white  group-data-[has-value=true]:text-white",
              popoverContent: "rounded-none",
            }}
          >
            {services.map((service) => (
              <SelectItem key={service.key} className="rounded-none">
                {service.label}
              </SelectItem>
            ))}
          </Select>
          <Select
            label="Year"
            variant="flat"
            className="max-w-xs rounded-none"
            classNames={{
              trigger: [
                "rounded-none text-white bg-secondG hover:bg-secondG text-white",
                "data-[hover=true]:bg-secondG",
              ],
              label: ["text-white", "group-data-[filled=true]:text-white"],
              value: "text-white  group-data-[has-value=true]:text-white",
              popoverContent: "rounded-none",
            }}
          >
            {services.map((service) => (
              <SelectItem key={service.key} className="rounded-none">
                {service.label}
              </SelectItem>
            ))}
          </Select>
        </div>
      </div>
      <div className="mt-[-2px] pt-[20px]">
        <div className="mt-[-5px] mb-[-5px]">
          <Title title={"ALL INSIGHTS"} />
        </div>
        <div className="mt-[37px] grid grid-cols-1 lg:grid-cols-2 gap-[20px]">
          {info.map((item, index) => (
            <AllInfo details={item} key={index} />
          ))}
        </div>
      </div>
      <div className="pt-[60px] pb-[80px]">
        <Explore buttonLabel={"View Insights"} />
      </div>
    </div>
  );
}

export default AllInsights;
