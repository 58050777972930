import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../../Css/swiperStyles.css";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

function ServiceDetailsSlider({
  slides,
  darkerVersion,
  blueVersion,
  includeIntro,
  slidesPerView,
  classNames,
  HideNavigation,
}) {
  const [showIntroOnDesktop, setShowIntroOnDesktop] = useState(
    window.innerWidth >= 1024
  );
  const [showIntroOnMobile, setShowIntroOnMobile] = useState(
    window.innerWidth < 1024
  );

  useEffect(() => {
    function handleResize() {
      setShowIntroOnDesktop(window.innerWidth >= 1024);
      setShowIntroOnMobile(window.innerWidth < 1024);
    }

    window.addEventListener("resize", handleResize);

    // Initial call to set the width on page load
    handleResize();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const titleRefs = useRef([]);

  useEffect(() => {
    const heights = titleRefs.current.map((ref) => ref.scrollHeight);
    const maxHeight = Math.max(...heights);

    titleRefs.current.forEach((ref) => {
      ref.style.height = `${maxHeight}px`;
    });
  }, []);

  var size = Object.keys(slides).length;
  const paginationRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && paginationRef.current) {
      const swiper = swiperRef.current.swiper;
      swiper.params.pagination.el = paginationRef.current;
      swiper.pagination.init();
      swiper.pagination.render();
      swiper.pagination.update();
    }
  }, [paginationRef.current]);
  return (
    <div
      className={`flex flex-col swiper-container max-lg:flex-col-reverse px-[10px] pt-[80px] xl:pl-44 2xl:pl-60 3xl:pl-[372px] ${
        size <= 2
          ? "xl:px-32 2xl:px-60 3xl:px-[372px]"
          : "xl:pl-44 2xl:pl-60 3xl:pl-[372px]"
      } ${classNames}`}
    >
      <div className={`flex gap-[25px] ${HideNavigation ? "" : "max-lg:pb-[20px]"}`}>
        {includeIntro && showIntroOnDesktop && (
          <div className="max-lg:hidden min-w-[350px]">
            <div
              className={`flex flex-col gap-[13px] w-[350px] h-[350px] justify-start py-[20px] px-4 ${
                darkerVersion
                  ? "bg-neutral-100 border-[0.5px] border-zinc-300"
                  : blueVersion
                  ? "bg-secondG"
                  : "bg-white border-[0.5px] border-zinc-300"
              }`}
            >
              <div className="uppercase h-[110px] text-white container-title">
                What sets PA Global apart as your transfer pricing partner?
              </div>
            </div>
          </div>
        )}
        <Swiper
          ref={swiperRef}
          centeredSlides={false}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            750: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: slidesPerView ? slidesPerView : includeIntro ? 3 : 4,
              spaceBetween: 20,
            },
          }}
          pagination={{
            el: paginationRef.current,
            clickable: true,
            renderBullet: (index, className) => {
              return `<span class="${className} border-1"></span>`;
            },
          }}
          onSwiper={(swiper) => {
            if (swiper.params.pagination.el && swiper.pagination && swiper.pagination.render && swiper.pagination.init) {
              swiper.pagination.el = paginationRef.current;
              swiper.pagination.render();
              swiper.pagination.init();
            }
          }}
          className="mySwiper"
          modules={[Pagination]}
        >
          <>
            {includeIntro && showIntroOnMobile && (
              <SwiperSlide style={{backgroundColor: "#43A548"}}>
                <div>
                  <div
                    className={`flex flex-col gap-[13px] max-w-[350px] h-[350px] justify-center py-10 px-4 ${
                      darkerVersion
                        ? "bg-neutral-100 border-[0.5px] border-zinc-300"
                        : blueVersion
                        ? "bg-secondG"
                        : "bg-white border-[0.5px] border-zinc-300"
                    }`}
                  >
                    <div className="uppercase h-[110px] text-white container-title">
                      What sets PA Global apart as your transfer pricing partner?
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            )}
            {slides.map((slide, index) => {
              const descriptionLines = slide.description.split("\n");
              return (
                <SwiperSlide
                  key={index}
                  className="group"
                  style={
                    window.innerWidth > 1024
                      ? size === 2
                        ? { width: "50%" }
                        : { width: 352, minWidth: 352, maxWidth: 352 }
                      : { width: "100%" }
                  }
                >
                  <div
                    className={` grid grid-cols-1 items-start h-[350px] px-5 py-5 pr-[10px]  transition-background hover:cursor-pointer ${
                      darkerVersion
                        ? "bg-neutral-100 border border-zinc-300"
                        : blueVersion
                        ? "bg-secondB"
                        : "bg-white border border-zinc-300  transition-background"
                    }`}
                  >
                    <div className="flex flex-col gap-5 h-full overflow-hidden group-hover:overflow-auto custom-scrollbar">
                      <div
                        className={`border container-title min-h-[42px] mb-[19px] w-[42px] flex items-center justify-center  transition-colors ${
                          blueVersion
                            ? "text-secondG border-secondG"
                            : "text-mainB border-mainB"
                        }`}
                      >
                        {index + 1}
                      </div>
                      <div
                        className="uppercase flex mt-[-1px] items-start justify-start text-secondG container-title  transition-colors"
                      >
                        {slide.title}
                      </div>
                      <p
                        className={`body-text mt-[-6px]  transition-colors pr-[25px] ${
                          blueVersion ? "text-white" : "text-mainB"
                        }`}
                      >
                        {descriptionLines.map((line, idx) => (
                          <span key={idx}>
                            {line}
                            <br />
                          </span>
                        ))}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </>
          {window.innerWidth > 1024 && window.innerWidth < 2015 ? (
            <>
              {size > 4 ? <SwiperSlide className="test"></SwiperSlide> : null}
              {size > 3 ? <SwiperSlide className="test"></SwiperSlide> : null}
            </>
          ) : null}
        </Swiper>
        <div className="custom-pagination" ref={paginationRef}></div>
      </div>
    </div>
  );
};
export default ServiceDetailsSlider;
