import { Input } from "@nextui-org/react";
import React from "react";
import PrimaryButtons from "../PrimaryButtons";

function NumberOfPersonnel({ setActiveIndex }) {
  function handleSubmit() {
    setActiveIndex(2);
  }
  return (
    <div>
      <div className="section-title">Number of Personnel</div>
      <form onSubmit={handleSubmit}>
        <div className="grid max-sm:grid-cols-1  pt-[55px] mt-[-2px] gap-y-[20px] gap-x-[20px]">
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="number"
              label=" Partners"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="number"
              label=" Seniors"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="number"
              label=" Semi Seniors"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="number"
              label=" Juniors"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="number"
              label=" Staff"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="number"
              label=" Administrative"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className="flex justify-end">
            <PrimaryButtons buttontype={"submit"} label={"Next"} />
          </div>
        </div>
      </form>
    </div>
  );
}

export default NumberOfPersonnel;
