import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Divider } from "@nextui-org/react";
import PrimaryButtons from "./PrimaryButtons";
import FeaturedBox from "./FeaturedBox";
import { useNavigate } from "react-router-dom";

const DropdownContent = ({
  href,
  label,
  FeaturedImage,
  FeaturedDesc,
  submenuscontent,
  setIsDrawerOpen,
  setIsActive,
  handleClick,
  labelDesc,
}) => {
  let navigate = useNavigate();
  const [hoveredItem, setHoveredItem] = useState(submenuscontent[0]);

  useEffect(() => {
    setHoveredItem(submenuscontent[0]);
  }, [submenuscontent]);

  function goTo() {
    navigate(href);
    handleClick();
  }

  window.onscroll = function (e) {
    // called when the window is scrolled.
    setIsActive(false);
  };

  const handleScroll = (event) => {
    event.stopPropagation();
    setIsDrawerOpen(false);
    setIsActive(false);
  };

  return (
    <div className="dropdown_content" onScroll={handleScroll}>
      <div className="col-span-2 w-full ">
        <p className="section-title text-secondG uppercase mb-[13px]">
          {label}
        </p>
        <Divider className="bg-cultured" />
      </div>
      <div className="col-span-1 flex items-end pb-[18px]">
        <div className="text-secondG container-title">Featured</div>
      </div>
      <div className="flex justify-between col-span-2">
        <div className="w-full h-[295px] flex flex-col justify-between">
          <p className="text-white w-3/4 section-paragraph mt-[13px] body-text">
            {labelDesc}
          </p>
          <PrimaryButtons
            action={goTo}
            label={`${label}`}
            className={"mt-[56px]"}
          />
        </div>
        <div className="w-full mt-[13px] flex h-[320px] justify-start custom-scrollbar overflow-y-auto">
          <ul>
            {submenuscontent.map((item, index) => (
              <React.Fragment key={index}>
                <li
                  onMouseEnter={() => setHoveredItem(item)}
                  onMouseLeave={() => setHoveredItem(submenuscontent[0])}
                  onClick={() => {
                    navigate(item.href);
                    handleClick();
                  }}
                  className="text-white section-paragraph hover:text-secondG hover:cursor-pointer pb-[8px]"
                >
                  {item.heading}
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      </div>
      <div className="w-full mt-[-21px]">
        <div>
          <FeaturedBox
            href={hoveredItem ? hoveredItem.href : href}
            img={
              hoveredItem && hoveredItem.FeaturedImage
                ? hoveredItem.FeaturedImage
                : FeaturedImage
            }
            FeaturedDesc={
              hoveredItem && hoveredItem.FeaturedDesc
                ? hoveredItem.FeaturedDesc
                : FeaturedDesc
            }
            handleClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default DropdownContent;
