import React from "react";
import HeaderImage from "../../assets/HeadersBackground/IndustriesCategories.png";
import Header from "../Header";
export default function IndustryHeader() {
  const title = "Industries";
  const description =
    "In today's dynamic landscape, technology drives unprecedented connections and opportunities. At PA, we excel in sector-specific expertise, anticipating needs and seizing opportunities. Our global network blends international insights with local understanding, staying ahead of trends to drive your success and sustainable growth.";

  return (
    <div>
      <Header title={title} image={HeaderImage} description={description} />
    </div>
  );
}
