import React from "react";
import PrimaryButtons from "./PrimaryButtons";

function Explore({ title, buttonLabel, action }) {

  return (
    <div className="flex max-lg:flex-col gap-4 items-center justify-center footerText">
      <PrimaryButtons label={buttonLabel}  action={action}/>
    </div>
  );
}

export default Explore;
