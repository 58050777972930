import React from "react";
import AIPic from "../../assets/Insights/AI-Insight.png";
import ESGPic from "../../assets/Insights/ESG-Insight.png";
import RISKPic from "../../assets/Insights/RISK-Insight.png";
import DIGPic from "../../assets/Insights/DIG-Insight.png";
import TAXPic from "../../assets/Insights/TAX-Insight.png";
import REPPic from "../../assets/Insights/REP-Insight.png";
import FINPic from "../../assets/Insights/FIN-Insight.png";
import STRPic from "../../assets/Insights/STR-Insight.png";
import Card from "../Card";
import { useNavigate } from "react-router-dom";
function InsightsCategories() {
  const navigate = useNavigate();
  const cards = [
    {
      title: "Artificial Intelligence",
      description:
        "Explore AI's role in boosting business efficiency, decision-making, and productivity.",
      image: AIPic,
    },
    {
      title: "ESG AND SUSTAINABILITY",
      description:
        "Understand how ESG and sustainability drive business growth and innovation.",
      image: ESGPic,
    },
    {
      title: "RISK MANAGEMENT",
      description:
        "Learn to balance risk to safeguard and advance your business.",
      image: RISKPic,
    },
    {
      title: "Digital Adoption and Transformation",
      description:
        "Boost business efficiency and innovation through digital transformation.",
      image: DIGPic,
    },
    {
      title: "Tax and Regulatory Change",
      description:
        "Navigate tax and regulatory changes with strategic insights.",
      image: TAXPic,
    },
    {
      title: "Reporting Standards",
      description:
        "Stay compliant with updates on international financial reporting standards.",
      image: REPPic,
    },
    {
      title: "Finance and Assurance",
      description:
        "Enhance financial stability and integrity through optimized management.",
      image: FINPic,
    },
    {
      title: "Strategy",
      description:
        "Craft adaptive strategies for growth and alignment with economic trends.",
      image: STRPic,
    },
  ];
  function NavigateToInsightsCategory() {
    navigate("/IndustriesCategory");
  }

  return (
    <div className="max-md:mt-[80px] min-h-screen max-lg:pr-[20px] max-lg:pl-[20px] px-[20px] py-[60px] xl:py-[80px] xl:px-32 2xl:px-60 3xl:px-[372px]">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[20px] gap-y-[39px] ">
        {cards.map((card) => (
          <Card details={card} action={NavigateToInsightsCategory} />
        ))}
      </div>
    </div>
  );
}

export default InsightsCategories;
