import React from "react";
import Header from "../Header";
import HeaderImage from "../../assets/HeadersBackground/ConnectHeader.png";
function ConnectHeader() {
  const title = "Building Bridges at PA Global";
  const description = `Explore our openings at PA Global to align your skills with our mission of excellence. Join our global network where trust, integrity, and collaboration drive success. Surpass your potential with PA Global.`;

  return (
    <div>
      <Header
        title={title}
        image={HeaderImage}
        description={description}
        bigTitle={true}
      />
    </div>
  );
}

export default ConnectHeader;
