import React from "react";
import ConnectHeader from "../Components/ConnectSections/ConnectHeader";
import FormSteps from "../Components/MemberFirmSections/FormSteps";

function BecomeAMemberFirmForm() {
  return (
    <div>
      <ConnectHeader />
      <FormSteps />
    </div>
  );
}

export default BecomeAMemberFirmForm;
