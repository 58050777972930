import React from "react";
import ImageHeader from "../../assets/ServiceDetails/SecondHeader.png";
import ServiceDetailsSlider from "./ServiceDetailsSlider";

function ServiceSecondInfo() {
  const servicesData = [
    {
      title: "Tailored In-House Training",
      description:
        `PA Global offers tailored in-house training programs designed to meet the specific needs of our clients' workforce.

These customized programs cover a range of topics, including, business practices, tax compliance, employment law, and cross-cultural communication. Through these training initiatives, employees can enhance their understanding of cultural differences, legal frameworks, and communication styles in business settings.

Moreover, PA Global offers specialized professional trainings on standards and frameworks such as IFRsS, ISA, ISQM 1 and 2, ensuring that participants gain a comprehensive understanding of industry best practices and compliance requirements.

They also gain insights into tax regulations, transfer pricing, tax reporting, and global mobility best practices.

Moreover, particularly for companies with a global workforce. employees receive guidance on navigating labor laws and regulations in different countries, Additionally, they develop essential skills for effective communication and collaboration across diverse cultures. PA Global's tailored in-house training empowers employees with the knowledge and skills needed to excel in any business environment.`,
    },
    {
      title: "Business Coaching Services",
      description:
      `We provide personalized support and guidance to clients in achieving their business goals. We offer one-on-one sessions with our experienced business coaches who offer valuable insights, feedback, and advice tailored to the specific needs and challenges of your business.

      These coaching sessions cover a wide range of topics, including leadership development, strategic planning, organizational management, problem-solving, and personal development.

      Benefit from our expertise and experience to enhance your skills, overcome obstacles, and drive success in your business endeavors.`,
    },
  ];

  return (
    <div className="bg-mainB">
      <div className="px-[20px]  pt-16 xl:pt-[80px] xl:px-32 2xl:px-60 3xl:px-[372px] bg-mainB">
        <div className="flex flex-col gap-[13px]">
          <div
            className={`text-secondG uppercase section-title mt-[-7px] mb-[-6px]`}
          >
            {"international training"}
          </div>
          <p className={`section-paragraph text-secondG`}>
            Building a More Informed Business World
          </p>
          <p className="text-white body-text lg:w-[718px] lg:mt-[-1px]">
            PA Global goes beyond simply serving clients. Actually, we believe
            in fostering a more informed business environment and thrive in the
            international arena by actively contributing to the knowledge and
            skillset of professionals in the broader business ecosystem.
          </p>
        </div>
        <div className="mt-[-3px] pt-[40px]">
          <img
            src={ImageHeader}
            alt="ServiceImage"
            className="rounded-none w-full object-cover"
          />
        </div>
      </div>
      <ServiceDetailsSlider
        blueVersion={true}
        slides={servicesData}
        slidesPerView={2}
        classNames={"pt-[60px] pb-[80px]"}
        HideNavigation={true}
      />
    </div>
  );
}

export default ServiceSecondInfo;
