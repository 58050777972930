// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-datepicker .ui-state-active {
    background-color: #ff0000 !important; /* Change to your desired background color */
    color: #ffffff !important; /* Change to your desired text color */
}
::-webkit-calendar-picker-indicator {
    filter: invert(1);
}`, "",{"version":3,"sources":["webpack://./src/Css/DateInput.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC,EAAE,4CAA4C;IAClF,yBAAyB,EAAE,sCAAsC;AACrE;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".ui-datepicker .ui-state-active {\n    background-color: #ff0000 !important; /* Change to your desired background color */\n    color: #ffffff !important; /* Change to your desired text color */\n}\n::-webkit-calendar-picker-indicator {\n    filter: invert(1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
