import React from "react";
import HeaderImage from "../../assets/HeadersBackground/ServicesCategory.png";
import Header from "../Header";

function HeaderServices() {
  const title = "Services";
  const description = `Our collaborative and transparent service portfolio includes assurance, business advisory, tax compliance and planning, risk assessment, and technology services. We prioritize integrity, objectivity, and confidentiality. With personalized strategies, we excel in meeting diverse client needs through a client-centric and proactive approach.`;

  return (
    <div>
      <Header title={title} image={HeaderImage} description={description} />
    </div>
  );
}

export default HeaderServices;
