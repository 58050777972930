import React from "react";
import post1 from "../../assets/Articles/Rectangle 6.png"
import post2 from "../../assets/Articles/Rectangle 8.png"
import post3 from "../../assets/Articles/Rectangle 7.png"
import post4 from "../../assets/Articles/Rectangle 9.png"
import { Link } from "react-router-dom";
import IndustriesInfo from "./IndustriesInfo";
function FeaturedIndustries() {
  const info = [
    {
      title: "Post 1",
      subtitle: "Brief post 1.",
      image: post1,
    },
    {
      title: "Post 2",
      subtitle: "Brief post 2.",
      image: post2,
    },
    {
      title: "Post 3",
      subtitle: "Brief post 3.",
      image: post3,
    },
    {
      title: "Post 4",
      subtitle: "Brief post 4.",
      image: post4,
    },
  ];
  return (
    <div className="px-[20px] pt-[80px] pb-[60px] xl:px-32 2xl:px-60 3xl:px-[372px]">
      <div className="">
        <div className="flex flex-col">
          <p className=" uppercase text-mainB section-title mb-[35px] mt-[-8px]">Trending Articles</p>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-[20px] mt-[-3px]">
            {info.map((item, index) => (
              <Link to={"/IndustriesDetails"}>
                {" "}
                <IndustriesInfo details={item} key={index} />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedIndustries;
