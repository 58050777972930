import React from "react";
import HeaderInsights from "../Components/InsightsPageSections/HeaderInsights";
import InsightsCategories from "../Components/InsightsPageSections/InsightsCategories";
import AllInsights from "../Components/InsightsPageSections/AllInsights";

function Insights() {
  return (
    <div className="">
      <HeaderInsights />
      <InsightsCategories />
      <AllInsights />
    </div>
  );
}

export default Insights;
