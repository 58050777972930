import { Button } from "@nextui-org/react";
import React from "react";

function PrimaryButtons({
  className,
  label,
  action,
  textClassname,
  buttontype,
}) {
  return (
    <Button
      className={`animatedButton buttons h-[54px] ${className} `}
      onPress={action}
      style={{ opacity: 100 }}
      type={buttontype ? buttontype : "button"}
    >
      <span className={` ${textClassname}`}> {label} </span>
    </Button>
  );
}

export default PrimaryButtons;
