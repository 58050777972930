import React from "react";
import DetailsInfo from "../DetailsInfo";
import WorkingAtPaImage from "../../assets/Careers/WorkingAtPa.png";
import { useNavigate } from "react-router-dom";

function WorkingAtPa() {
  let navigate = useNavigate();
  const firstData = {
    image: WorkingAtPaImage,
    title: "Working at PA",
    subtitle: "Experience Beyond Potential: Your Journey of Growth.",
    description: `Beyond Potential: Your Journey of Growth. represents our dedication to fully nurturing your skills and ambitions. With comprehensive training, mentorship, a culture of continuous development, you'll embark on a transformative journey.      
               \n   We empower you to surpass your potential, achieving remarkable growth and success. Our innovative team delivers exceptional client experiences daily. Our diverse, inclusive culture values, hears, and supports everyone.
               \n   Meet our team and see how they've experienced Beyond Potential at PA Global. Embrace the power of being you. Ready for your career growth journey? Let's work together!`,
  };
  const buttonData = {
    label: "About Us",
  };
  function goTo(){
    navigate("/AboutUs");
  }
  return (
    <div className="">
      <DetailsInfo
        data={firstData}
        flipped={true}
        haveButton={true}
        buttonData={buttonData}
        action={goTo}
      />
    </div>
  );
}

export default WorkingAtPa;
