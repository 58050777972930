import React from "react";
import IndustriesCategories from "../Components/IndustriesCategorySections/IndustriesCategories";
import AllIndustries from "../Components/IndustriesCategorySections/AllIndustries";
import IndustryHeader from "../Components/IndustriesCategorySections/IndustryHeader";

function Industries() {
  return (
    <div>
      <IndustryHeader />
      <IndustriesCategories />
      <AllIndustries />
    </div>
  );
}

export default Industries;
