import React from "react";
import Card from "../Card";
import Assurance from "../../assets/ServicesCategory/Insights blog link.png";
import Advisory from "../../assets/ServicesCategory/Rectangle 12.png";
import Risk from "../../assets/ServicesCategory/Rectangle 16 (3).png";
import Tax from "../../assets/ServicesCategory/Rectangle 16 (4).png";
import Technology from "../../assets/ServicesCategory/Rectangle 17 (3).png";
import { useNavigate } from "react-router-dom";
import Title from "../Title";

function ServicesCategories() {
  let navigate = useNavigate();
  const cards = [
    {
      title: "Assurance",
      description:
        "Navigating the Landscape of Decisions",
      image: Assurance,
    },
    {
      title: "Advisory",
      description:
        "Navigating the Complexities of the Business World",
      image: Advisory,
    },
    {
      title: "RISK",
      description:
        "Risk Management at Our Services",
      image: Risk,
    },
    {
      title: "Tax",
      description:
        "Navigating Complexities with Precision",
      image: Tax,
    },
    {
      title: "Technology",
      description:
        "Safeguarding Your Digital Journey",
      image: Technology,
    },
  ];
  function navigateToInsightCategory() {
    navigate("/ServiceDetails");
  }
  return (
    <div className="px-[20px] py-[80px] xl:px-32 2xl:px-60 3xl:px-[372px] bg-mainB">
      <div className="flex flex-col gap-[80px]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[20px] mt-[-1px] gap-y-[39px]">
            {cards.map((card, index) => (
              <div className="text-white">
                <Card
                  titleIsWhite={true}
                  details={card}
                  key={index}
                  action={navigateToInsightCategory}
                />
              </div>
            ))}
          </div>
      </div>
    </div>
  );
}

export default ServicesCategories;
