import React, { useRef, useState } from "react";
import CustomTextField from "../CustomTextField";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { Autocomplete, AutocompleteItem, Input } from "@nextui-org/react";

import { countries } from "../../assets/JSON/countries";
import PrimaryButtons from "../PrimaryButtons";

function GeneralInformations({ setActiveIndex }) {
  const fileInputRef = useRef(null);
  const [fileUploaded, setFileUploaded] = useState(null);

  const handleDivClick = () => {
    // When the div is clicked, trigger the file input click
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    // Get the selected file
    const file = event.target.files[0];
    setFileUploaded(file);
  };
  function handleSubmit() {
    console.log("hello");
    setActiveIndex(1);
  }
  return (
    <div>
      <div className="section-title">General Information</div>
      <form onSubmit={handleSubmit}>
        <div className="grid max-sm:grid-cols-1  pt-[55px] mt-[-2px] gap-y-[20px] gap-x-[20px]">
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="string"
              label=" Firm  Name"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Autocomplete
              isRequired
              required
              variant={"bordered"}
              radius={"none"}
              placeholder="Firm Address"
              label="Firm Address"
              labelPlacement="outside-left"
              classNames={{
                base: "h-full",
                inputWrapper: "h-[60px] shadow-none border-1",
                popoverContent: " rounded-none ",
              }}
              inputProps={{
                classNames: {
                  inputWrapper: "h-[60px] shadow-none border-1",
                  base: "w-full",
                  mainWrapper: "w-full",
                  label:
                    "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
                },
              }}
            >
              {countries.map((country) => (
                <AutocompleteItem key={country.value} value={country.value}>
                  {country.label}
                </AutocompleteItem>
              ))}
            </Autocomplete>
          </div>
          <div className="col-span-1 max-sm:col-span-2 flex w-full justify-between items-center">
            <div className="w-full container-title max-lg:!text-[12pt] !text-[14pt]">Firm License</div>
            <div className="w-full">
              <div
                onClick={handleDivClick}
                className={`h-[60px] border-1 bg-white flex items-center pl-[20px] cursor-pointer ${
                  fileUploaded ? "text-secondG" : "text-mainB"
                }`}
              >
                <ArrowUpTrayIcon className="w-5 h-5 mr-[10px]" />
                {fileUploaded ? "Document Uploaded" : "Upload Document"}
              </div>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
                multiple // Remove this line if you want to restrict to a single file
              />
              <p className="text-secondG text-xs mt-1 mb-[-4px] pl-[10px]">
                {fileUploaded
                  ? fileUploaded.name
                  : "(File types: pdf, doc, docx, txt, rtf)"}
              </p>
            </div>
          </div>
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="string"
              label=" Contact Person"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="string"
              label=" Email"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="string"
              label=" Number of Branches"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="string"
              label=" Locations"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="string"
              label=" Year of Foundation"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="string"
              label=" Website"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="string"
              label=" Has your firm name changed during the last 5 years? If yes, please state previous name(s):"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full max-md:flex-col",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="string"
              label="Does your firm generally use third parties/ sub-contractors to render services? If yes, give details or reasons and contents:"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full max-md:flex-col",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="string"
              label="Your fellowship number:"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full max-md:flex-col",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className=" col-span-1 max-sm:col-span-2 ">
            <Input
              isClearable
              isRequired
              required
              radius={"none"}
              variant="bordered"
              type="string"
              label="Do you have any problems with your professional bodies?"
              labelPlacement="outside-left"
              className="h-[60px] w-full"
              classNames={{
                inputWrapper: "h-[60px] shadow-none border-1",
                base: "w-full max-md:flex-col",
                mainWrapper: "w-full",
                label:
                  "w-full container-title max-lg:!text-[12pt] !text-[14pt]",
              }}
            />
          </div>
          <div className="flex justify-end">
            <PrimaryButtons buttontype={"submit"} label={"Next"} />
          </div>
        </div>
      </form>
    </div>
  );
}

export default GeneralInformations;
