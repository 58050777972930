// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
    background: var(--surface-card);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.p-tabview-nav-content {
    border-bottom: 0.5pt solid #D9D9D9;
}

.p-tabview-ink-bar {
    background-color: #43A548 !important;
    height: 3px !important;
}


@media (min-width: 1280px) {
    .p-tabview-nav-container {
        padding-left: 8rem;
        /* 44 * 0.25rem */
        padding-right: 8rem;
    }

    .p-tabview-panels {
        padding-left: 8rem;
        /* 44 * 0.25rem */
    }
}

@media (min-width: 1536px) {
    .p-tabview-nav-container {
        padding-left: 15rem;
        /* 60 * 0.25rem */
        padding-right: 15rem;
    }
    .p-tabview-panels {
        padding-left: 15rem;
        /* 44 * 0.25rem */
    }
}

@media (min-width: 1700px) {
    .p-tabview-nav-container {
        padding-left: 372px;
        padding-right: 372px;
    }
    .p-tabview-panels {
        padding-left: 372px;
        /* 44 * 0.25rem */
    }
}
`, "",{"version":3,"sources":["webpack://./src/Css/TabsCss.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;AAC1B;;;AAGA;IACI;QACI,kBAAkB;QAClB,iBAAiB;QACjB,mBAAmB;IACvB;;IAEA;QACI,kBAAkB;QAClB,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,mBAAmB;QACnB,iBAAiB;QACjB,oBAAoB;IACxB;IACA;QACI,mBAAmB;QACnB,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,mBAAmB;QACnB,oBAAoB;IACxB;IACA;QACI,mBAAmB;QACnB,iBAAiB;IACrB;AACJ","sourcesContent":[".tabs {\n    background: var(--surface-card);\n    border-radius: 10px;\n    margin-bottom: 1rem;\n}\n\n.p-tabview-nav-content {\n    border-bottom: 0.5pt solid #D9D9D9;\n}\n\n.p-tabview-ink-bar {\n    background-color: #43A548 !important;\n    height: 3px !important;\n}\n\n\n@media (min-width: 1280px) {\n    .p-tabview-nav-container {\n        padding-left: 8rem;\n        /* 44 * 0.25rem */\n        padding-right: 8rem;\n    }\n\n    .p-tabview-panels {\n        padding-left: 8rem;\n        /* 44 * 0.25rem */\n    }\n}\n\n@media (min-width: 1536px) {\n    .p-tabview-nav-container {\n        padding-left: 15rem;\n        /* 60 * 0.25rem */\n        padding-right: 15rem;\n    }\n    .p-tabview-panels {\n        padding-left: 15rem;\n        /* 44 * 0.25rem */\n    }\n}\n\n@media (min-width: 1700px) {\n    .p-tabview-nav-container {\n        padding-left: 372px;\n        padding-right: 372px;\n    }\n    .p-tabview-panels {\n        padding-left: 372px;\n        /* 44 * 0.25rem */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
