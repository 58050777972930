import React from "react";
import Title from "../Title";
import OurMissionCard from "./OurMissionCard";
import { ReactComponent as DartBoard } from "../../assets/SVG/dartboard.svg";
import { ReactComponent as Magnifier } from "../../assets/SVG/magnifier.svg";
import { ReactComponent as Court } from "../../assets/SVG/court.svg";

const iconMap = {
  DartBoard,
  Magnifier,
  Court,
};

const cardData = [
  {
    icon: "DartBoard",
    text: "To be a benchmark for excellence in the professional industry, shaping its future through global collaboration and high standards.",
  },
  {
    icon: "Magnifier",
    text: "To elevate industry standards with exceptional, precise, and dependable services, adding value at every stage.",
  },
  {
    icon: "Court",
    text: "To empower clients with industry-leading solutions and expertise, delivering ethical and exceptional service that surpasses expectations.",
  },
];
function OurMission() {
  return (
    <div className="px-[10px] pt-[80px] lg:px-5 xl:px-32 2xl:px-60 3xl:px-[372px] ">
      <div className="mt-[-7px] ml-[-2px] mb-[6px]">
        <Title title={"Our Mission"} />
      </div>
      <div className="text-white section-paragraph lg:w-2/3">
        Perseverance and Excellence: Shape the Future of Professional Services
      </div>
      <div className="grid items-center justify-center max-sm:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[20px] mt-[56px]">
        {cardData.map((card, index) => {
          const IconComponent = iconMap[card.icon];
          return (
            <OurMissionCard
              key={index}
              IconComponent={IconComponent}
              text={card.text}
            />
          );
        })}
      </div>
    </div>
  );
}

export default OurMission;
