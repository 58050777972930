import React from "react";
import HeaderImage from "../../assets/HeadersBackground/InsightsSection.png";
import Header from "../Header";

function HeaderInsights() {
  const title = "Insights";
  const description = `Our enhanced business Insights section provides executives with
              straightforward and strategic perspectives on key challenges:
              reshaping business models, addressing climate change and
              sustainability, and cultivating trust amidst rapid technological
              advancements.`;

  return (
    <div>
      <Header title={title} image={HeaderImage} description={description} />
    </div>
  );
}

export default HeaderInsights;
