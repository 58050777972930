import React from "react";
import NewsHeader from "../assets/NewsCover/Insights blog link.png";
import { Image } from "@nextui-org/react";
import InsightFromDetails from "../Components/NewsDetailsSections/InsightsFromDetails";
function NewsDetails() {
  return (
    <div className="">
      <div className="max-lg:pr-[20px] max-lg:pl-[20px] py-[80px] mb-[-4px] xl:px-32 2xl:pr-[618px] 2xl:px-[600px] w-full">
        <div className="flex justify-center items-center ">
          <div className="flex flex-col 3xl:w-[800px]">
            <p className=" uppercase section-title text-mainB pb-[60px] mt-[-7px]">
              Artificial intelligence
            </p>
            <p className="pb-[21px] mt-[-13px] body-text lg:w-[626px]">
              The integration of AI, robotics, and coding into STEM education is
              vital for preparing students for the modern workforce. Through
              hands-on experiences, these initiatives cultivate essential
              skills, ensuring students are well-equipped for success in a
              technology-driven world.
            </p>
            <Image
              alt="NextUI hero Image"
              src={NewsHeader}
              className="rounded-none pb-[39px] mt-[-4px]"
            />
            <h1 className="container-title  uppercase lg:w-[718px] mt-[-1px] pb-[20px]">
              STEM Education: Building the Workforce of the Future
            </h1>
            <p className="body-text mt-[-6px]">
              Across the globe, integrating Science, Technology, Engineering,
              and Mathematics (STEM), including robotics, coding, and other
              technology-focused disciplines, is becoming increasingly prevalents
              in school curricula. <br /> The integration of artificial
              intelligence <b>(AI)</b> into STEM education aiming to enhance
              learning experiences and expose students to cutting-edge
              technologies is becoming a necessity to enrich students’
              educational experience and prepare them for the demands of the
              21st-century workforce. <div className="w-full py-[5px]" />
              In these STEM education initiatives, students often have the
              opportunity to engage in hands-on learning experiences,
              experiments, and projects that promote problem-solving, critical
              thinking, and creativity. <div className="w-full py-[5px]" />{" "}
              Robotics and coding activities, in particular, are popular
              components of STEM programs, as they provide students with
              practical skills in programming, engineering design, and robotics
              construction. <br /> While many schools already offer STEM
              education and robotics programs, there is room for potential for
              further expansion and enhancement.{" "}
              <div className="w-full py-[5px]" /> Schools may continue to
              invest in these areas by expanding course offerings, integrating
              new technologies, fostering partnerships with industry and
              academia, and providing professional development opportunities for
              educators. This ongoing commitment to STEM education ensures that
              students are equipped with the skills and knowledge needed to
              succeed in an increasingly technology-driven world.{" "}
              <div className="w-full py-[5px]" /> In conclusion, robotics and
              coding, in particular, are popular components due to their ability
              to equip students with practical skills in programming,
              engineering design, and robot construction.{" "}
              <div className="w-full py-[5px]" /> <b>NB: STEM or STEAM? </b>{" "}
              <br /> The term "STEM" stands for Science, Technology,
              Engineering, and Mathematics, whereas "STEAM" includes an
              additional component, "Art," making it Science, Technology,
              Engineering, Arts, and Mathematics. Both STEM and STEAM education
              initiatives aim to prepare students with skills relevant to the
              modern workforce, but STEAM incorporates artistic elements into
              the curriculum to foster creativity and innovation alongside
              technical proficiency.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-mainB">
        <InsightFromDetails />
      </div>
    </div>
  );
}

export default NewsDetails;
