import React from "react";
import ImageHeader from "../../assets/ServiceDetails/SecondHeader.png";
function ServiceDetailsSecondHeader() {
  return (
    <div className="px-[20px] xl:px-32 2xl:px-60 mt-[-5px] 3xl:px-[372px] pt-[80px]">
      <div className="flex flex-col items-start justify-start w-full">
        <div className={`text-mainB uppercase section-title mt-[-2px] ml-[-4px]`}>
          {"Due Diligence"}
        </div>
        <p className=" xl:w-[718px] smallNotoParagraphText mt-[-17px] pt-[20px]">
          Making critical business decisions: acquiring a company, investing in
          a start-up, buying a real estate, or recruiting atop talent – requires
          careful consideration. At PA Global, we are here to guide you through
          the due diligence process, ensuring you have the comprehensive
          investigative information you need to help you making informed
          decisions.
        </p>
        <img
          src={ImageHeader}
          alt="ServiceDetailsImg"
          className="rounded-none h-full w-full object-cover mt-[-6px] pt-[40px]"
        />
      </div>
    </div>
  );
}

export default ServiceDetailsSecondHeader;
