import React from "react";
import Title from "../Title";
import IndustryCard from "../IndustryCards";
import FinancialPIC from "../../assets/Industries/Financial.png";
import ConsumerPIC from "../../assets/Industries/Consumer.png";
import HospitalityPIC from "../../assets/Industries/Hospitality.png";
import HealthPIC from "../../assets/Industries/HealthCare.png";
import PrimaryButtons from "../PrimaryButtons";
import { useNavigate } from "react-router-dom";
function Industries() {
  let navigate = useNavigate();
  function goTo() {
    navigate("/Industries");
  }
  const cards = [
    {
      title: "FINANCIAL SERVICES",
      description:
        "PA is dedicated to supporting financial institutions in optimizing processes, enhancing customer experiences.",
      image: FinancialPIC,
    },
    {
      title: "CONSUMER AND RETAIL",
      description:
        "Our custom solutions for Consumer and Retail sectors help your business thrive. Leveraging insights and strategies keeps you ahead.",
      image: ConsumerPIC,
    },
    {
      title: "HOSPITALITY AND LEISURE",
      description:
        "PA positions itself as your ultimate ally in the hospitality and leisure sector, setting new benchmarks for satisfaction.",
      image: HospitalityPIC,
    },
    {
      title: "PHARMACEUTICALS AND HEALTHCARE",
      description:
        "In the pharmaceutical and healthcare realm, PA is your pioneering partner, navigating regulatory complexities.",
      image: HealthPIC,
    },
  ];
  return (
    <div className=" bg-mainB  px-[20px] max-lg:pr-[20px] max-lg:pl-[20px] py-[80px] xl:px-32 2xl:px-60 3xl:px-[372px]">
      <div className="justify-start items-start w-full pb mt-[-7px]">
        <Title title={"Industries"} className={"text-start"} />
        <h1 className="text-start text-white  body-text mt-[-13px] pt-[20px]">
          Explore our customized strategies and innovative solutions <br/> for 
          navigating your business's industry landscape successfully.
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2  gap-[20px] lg:gap-x-[20px] lg:gap-y-[40px] pt-[55px]">
        {cards.map((card, index) => (
          <IndustryCard details={card} key={index} />
        ))}
      </div>
      <div className="pt-[60px] flex items-center justify-center ">
        <PrimaryButtons label={"View Industries"} action={goTo} className={""}/>
      </div>
    </div>
  );
}

export default Industries;
