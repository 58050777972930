import React from "react";
import Title from "../Title";
import CustomTextField from "../CustomTextField";
import { Checkbox, CheckboxGroup, cn } from "@nextui-org/react";
import { TextField } from "@mui/material";
import PrimaryButtons from "../PrimaryButtons";

function ContactUs() {
  const [isSelected, setIsSelected] = React.useState(false);

  return (
    <div>
      <Title
        title={"Contact Us"}
        className={" ml-[-2px] mt-[66px] mb-[53px]"}
      />
      <div className="grid grid-cols-2 gap-y-[20px] mt-[10px] gap-x-[20px]">
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField
            label="First Name"
            id="fullWidth"
            variant="outlined"
          />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField
            label="Last Name"
            id="fullWidth"
            variant="outlined"
          />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField label="Company" id="fullWidth" variant="outlined" />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField
            label="Job Title"
            id="fullWidth"
            variant="outlined"
          />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField label="Email" id="fullWidth" variant="outlined" />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField
            label="Phone Number"
            id="fullWidth"
            variant="outlined"
          />
        </div>
        <div className="col-span-2 max-sm:col-span-2 mt-[-7px] mb-[6px]">
          <CheckboxGroup
            radius="none"
            label="RFP Service Desired"
            orientation="horizontal"
            color="primary"
            className="checkboxGroup"
            classNames={{
              label: "font-semibold Label-Color mb-[4px]",
            }}
          >
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 mt-[2px] gap-5">
              {" "}
              {/* Adjust gap size as needed */}
              {["assurance", "advisory", "risk", "tax", "technology"].map(
                (service) => (
                  <div
                    key={service}
                    className="col-span-1 bg-white p-5 border border-gray-300 rounded-none w-full"
                  >
                    <Checkbox
                      value={service}
                      size="md"
                      aria-label={
                        service.charAt(0).toUpperCase() + service.slice(1)
                      } // Capitalizes the first letter
                      classNames={{
                        base: cn(
                          "inline-flex items-center justify-start w-full rounded-none"
                        ),
                        label: "w-full Label-Color",
                      }}
                      onValueChange={() => setIsSelected(service)} // Custom function to handle change
                    >
                      <span className="text-mainB">
                        {service.charAt(0).toUpperCase() + service.slice(1)}{" "}
                        {/* Capitalizes the first letter */}
                      </span>
                    </Checkbox>
                  </div>
                )
              )}
            </div>
          </CheckboxGroup>
        </div>
        <div className="col-span-2 max-sm:col-span-2">
          <CustomTextField
            label="RFP Deadline"
            id="fullWidth"
            variant="outlined"
          />
        </div>
        <div className="col-span-2 mt-[-6px]">
          <TextField
            fullWidth
            sx={{
              "& .MuiInputLabel-root": {
                color: "#002C54",
                fontFamily: "NotoSansReg",
              },
              "& label": {
                paddingLeft: (theme) => theme.spacing(1),
                color: "#002C54",
                lineHeight: "20.52px",
              },
              "& input": {
                paddingLeft: (theme) => theme.spacing(1.5),
                color: "#002C54",
              },
              "& fieldset": {
                paddingLeft: (theme) => theme.spacing(1.5),
                borderColor: "#DEE1E3", // Default border color
              },
              "& .MuiOutlinedInput-root": {
                height: "90px",
                display: "flex",
                alignItems: "start",
                backgroundColor: "white",
                "& fieldset": {
                  transition: "border-color 0.3s",
                  borderRadius: "0px",
                },
                "&:hover fieldset": {
                  borderColor: "#DEE1E3", // Set hover border color to match default
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#DEE1E3",
                },
              },
            }}
            label="Message"
            id="fullWidth"
            variant="outlined"
          />
        </div>

        <div className="mt-[40px] mb-[60px]">
          <PrimaryButtons label={"Submit "} className={"px-[10px]"} />
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
