import { Divider } from "@nextui-org/react";
import React from "react";
import { ReactComponent as Pointer } from "../../assets/SVG/LocationsPointer.svg";
import BorderedArrow from "../BorderedArrow";
import { useNavigate } from "react-router-dom";
function CareerCard({ data }) {
  const navigate = useNavigate();
  function goTo() {
    navigate("/JobDetails");
  }
  return (
    <div
      className="h-[370px] w-[370px] text-mainB p-[20px] bg-cultured flex flex-col"
      key={data.id}
    >
      <div className="flex justify-between body-text mt-[-3px]">
        <span> {data.Date} </span>
      </div>
      <div className="flex justify-between container-title text-secondG mt-[13px]">
        <span> {data.Position} </span>
      </div>
      <div className="flex justify-between body-text items-center mt-[36px]">
        <span className="flex items-center justify-start gap-[10px] w-full">
          <Pointer />
          <span> {data.location} </span>
        </span>
      </div>
      <div>
        <ul className="list-disc list-outside section-paragraph pl-[20px] mt-[28px]">
          {data.responsibilities.map((item, index) => (
            <li key={index} className={index > 0 ? "mt-[8px]" : ""}>
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="flex justify-between h-full body-text items-end">
        <div className="flex justify-between w-full items-end">
          <p className="mb-[-4px]">{data.candidates}+ Candidates</p>
          <BorderedArrow action={goTo} />
        </div>
      </div>
    </div>
  );
}

export default CareerCard;
