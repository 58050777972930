import { Divider } from "@nextui-org/react";
import React from "react";

function ServiceDetailAccordion({ title, description }) {
  return (
      <div className="flex flex-col">
        <Divider />
        <div>
          <div className="px-[10px] flex max-lg:flex-col max-lg:gap-4 py-[37px]">
            <div className="uppercase min-w-72 text-secondG">
              <p className="w-full AvenirSmallTitle">{title}</p>
            </div>
            <div className="text-mainB smallNotoParagraphText">
              <p>{description}</p>
            </div>
          </div>
          <Divider />
        </div>
      </div>
  );
}

export default ServiceDetailAccordion;
