import React from "react";
import BorderedArrow from "./BorderedArrow";

function AllInfo({ details, blueText = false }) {
  return (
    <div className="border-b-1 border-[#A5A5A5] pb-[20px]">
      <div className="flex gap-4 lg:items-center  text-sm">
        <div className="flex gap-[20px] items-center flex-1 w-full">
          <img
            src={details.image}
            alt="ServicesInfoImg"
            className="h-[80px] w-[80px] object-cover"
          />
          <div
            className={` flex flex-col gap-[10px]  lg:min-w-[350px] ${
              blueText ? "text-mainB" : "text-white"
            }`}
          >
            <h1 className="font-bold container-title">{details.title} </h1>
            <p className=" overflow-hidden body-text ml-[-1px]">
              {details.subtitle}{" "}
            </p>
          </div>
        </div>
        <div className=" flex items-center justify-center gap-[40px] body-text">
          <p className="max-lg:hidden ">{details.description}</p>
          <div className=" flex justify-end">
            <BorderedArrow />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllInfo;
