import React from "react";
import Header from "../Header";
import HeaderImage from "../../assets/HeadersBackground/CareersHeader.png";

function CareersHeader() {
  const title = "A Legacy of Excellence";
  const description = `At PA Global, we transform local roots into global impact through professionalism and excellence. Our four cornerstones trust, integrity, collaboration, and meticulous attention to detail define our success. Join us to be part of a brand that excels with precision and ensures reliability. Explore career opportunities and help shape the future with PA Global.`;

  return (
    <div>
      <Header title={title} image={HeaderImage} description={description} />
    </div>
  );
}

export default CareersHeader;
