import React from "react";
import Title from "../Title";
import { ReactComponent as Safety } from "../../assets/SVG/safety.svg";
import { ReactComponent as Collab } from "../../assets/SVG/collab.svg";
import { ReactComponent as Star } from "../../assets/SVG/star.svg";
import { ReactComponent as Hands } from "../../assets/SVG/hands.svg";
import ValueCard from "./ValueCard";

const iconMap = {
  Safety,
  Collab,
  Star,
  Hands,
};

const cardData = [
  {
    icon: "Hands",
    text: [
      "Forging trust through open communication",
      "Anticipating challenges.",
      "Exceeding expectations.",
      "Building and maintaining strong, transparent bonds with clients, partners, and communities.",
      "Ensuring reliability and confidence in all interactions.",
    ],
    className: " ",
    contentClassName: " col-span-2 ",
    iconClassName: " ",
  },
  {
    icon: "Star",
    text: [
      "Upholding the highest ethical standards.",
      "Promoting transparency and accountability.",
      "Ensuring fairness in all dealings.",
      "Building a reputation for honesty and reliability.",
      "Strengthening relationships through ethical practices.",
    ],
    className: " col-span-1 ",
    contentClassName: " ",
    iconClassName: " ",
  },
  {
    icon: "Safety",
    text: [
      "Committing to excellence.",
      "Maintaining a relentless focus on precision and thoroughness in all aspects of our work.",
      "Ensuring the highest quality outcomes.",
      "Conducting comprehensive research.",
      "Meticulously analyzing data.",
      "Leaving no detail unexplored.",
    ],
    className: "col-span-1 md:col-span-2 ",
    contentClassName: " flex-row ",
    iconClassName: " ml-[-3px] ",
  },
];
function Value() {
  return (
    <div className=" px-[10px] py-[80px] lg:px-5 xl:px-32 2xl:px-60 3xl:px-[372px]">
      <div className="mt-[-8px] mb-[3px]">
        <Title title={"Values"} />
      </div>
      <div className="text-mainB section-paragraph lg:w-2/3 mt-[5px]">
        We build our success on a strong foundation of values. These values are
        our bedrock, guiding us in every decision we make and every action we
        take
      </div>
      <div className="grid items-center justify-center gap-[20px] mt-[36px] grid-cols-1 md:grid-cols-2">
        {cardData.map((card, index) => {
          const IconComponent = iconMap[card.icon];
          return (
            <ValueCard
              key={index}
              IconComponent={IconComponent}
              text={card.text}
              className={card.className}
              contentClassName={card.contentClassName}
              iconClassName={card.iconClassName}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Value;
