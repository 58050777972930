import React from "react";
import { ReactComponent as DartBoard } from "../../assets/SVG/dartboard.svg";

function OurMissionCard({ IconComponent, text }) {
  return (
    <div className="relative w-full pb-[80%] sm:pb-[65%]">
      {" "}
      {/* Square aspect ratio */}
      <div className="absolute top-0 left-0 w-full h-full max-lg:p-5 text-white p-[20px] bg-secondB flex flex-col">
        <div className="text-start flex flex-col items-start gap-5 justify-start">
          <IconComponent className="w-[60px] h-[60px]" />
          <div className="mt-[9px] ml-[-1px]">{text}</div>
        </div>
      </div>
    </div>
  );
}

export default OurMissionCard;
