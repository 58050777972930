import React from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButtons from "./PrimaryButtons";
function FeaturedBox({ href, img, FeaturedDesc, handleClick }) {
  let navigate = useNavigate();

  function goTo() {
    navigate(href);
    handleClick();
  }
  return (
    <div className="relative h-[326px] w-full pb-[100%]">
      <div className="absolute h-[326px] w-full inset-0 ">
        <div className=" text-white h-[326px] w-full bg-secondB">
          <div className="p-5 h-[326px] w-full">
            <div className="group relative">
              <img src={img} className="rounded-none object-cover h-[240px] w-full" />
              <div className="top-0 absolute h-full w-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in">
                <div className="absolute flex items-center justify-center h-full w-full opacity-100 z-10">
                  <PrimaryButtons
                    label={"Read more"}
                    className={"hover:opacity-100"}
                    action={goTo}
                  />
                </div>
                <div className="bg-mainB opacity-70 h-full" />
              </div>
            </div>

            <div className="text-white body-text mb-[-4px] mt-[15px] ml-[-1px] w-full flex gap-[20px] items-start">
              {FeaturedDesc}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedBox;
