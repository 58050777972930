// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .accordionItem {
    height: 80px !important;
}

.accordionItem h2{
    height: 100% !important;
} */`, "",{"version":3,"sources":["webpack://./src/Css/Accordion.css"],"names":[],"mappings":"AAAA;;;;;;GAMG","sourcesContent":["/* .accordionItem {\n    height: 80px !important;\n}\n\n.accordionItem h2{\n    height: 100% !important;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
