import React from "react";
import Title from "../Title";
import PrimaryButtons from "../PrimaryButtons";
import { useNavigate } from "react-router-dom";

function BecomeAMemberFirm() {
  const navigate = useNavigate()
  function goTo(){
    navigate("/BecomeAMemberFirm")
  }
  return (
    <div>
      <div className="lg:flex justify-between">
        <Title title={"Become a member firm"} />
        <PrimaryButtons label={"Apply"} className={" mt-[-5px]"} action={goTo}/>
      </div>

      <div className="section-paragraph mt-[45px]">
        It is our pleasure to welcome your interest in joining{" "}
        <span className=" font-bold ">PA Global</span>. We appreciate your time
        and effort in this process and look forward to reviewing your
        submission.
      </div>
      <div className="container-title mt-[32px] mb-[7px]">
        Non-Disclosure Commitment
      </div>
      <p>
        In connection with the <span className=" font-bold ">PA Global</span>{" "}
        Member Firms Questionnaire, we, PA Global, acknowledge, that it is in
        the process of receiving from the potential member firm, sensitive,
        confidential, and proprietary information, that includes financial and
        other internal administrative data, that are not generally known to the
        public, whether in tangible or intangible form.
      </p>
      <div className="mt-[25px]">
        <span className=" font-bold ">PA Global</span> hereby acknowledges that
        it is committed:
      </div>
      <ul className=" list-outside list-disc ml-[18px] mt-[4px]">
        <li>
          To keep all information that will be received through the
          questionnaire strictly confidential by using a reasonable degree of
          care, but not less than the degree of care used by it in safeguarding
          its confidential information.
        </li>
        <li>
          To use the information received solely in connection with the current
          or contemplated business relationship with the potential member firm
          and not for any other purpose.
        </li>
        <li>
          Not to disclose or share any information received by{" "}
          <span className=" font-bold ">PA Global</span> to any third parties.
        </li>
        <li>
          To be responsible for any breach of this declaration by any of its
          representatives.
        </li>
      </ul>
      <div className="mt-[26px]">
        This declaration represents an integral part of the questionnaire.
      </div>
      <div className="font-bold mt-[5px]">Hicham El-Moukammal</div>
      <div className="font-bold mt-[5px]">Chairman and CEO</div>
    </div>
  );
}

export default BecomeAMemberFirm;
