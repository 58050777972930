import React from "react";
import { TextField } from "@mui/material";

const CustomTextField = ({
  label,
  id,
  type,
  variant,
  fullWidth = true,
  classname,
  ...props
}) => {
  return (
    <div className={classname}>
      <TextField
        placeholder=" "
        type={type}
        fullWidth={fullWidth}
        sx={{
          "& .MuiInputLabel-root": {
            color: "#002C54",
            height: "100%",
            marginTop: "3px",
            fontFamily: "NotoSansReg",
          },
          "& .MuiInputBase-input": {
            fontFamily: "NotoSansReg",
          },
          "& .MuiInputBase-root": {
            backgroundColor: "white",
          },
          "& label": {
            paddingLeft: (theme) => theme.spacing(1),
            color: "#002C54",
            fontWeight: 400,
            lineHeight: "20.52px",
            // marginTop: "-1px",
          },
          "& input": {
            paddingLeft: (theme) => theme.spacing(1.5),
            color: "#002C54"
          },
          "& fieldset": {
            paddingLeft: (theme) => theme.spacing(1.5),
            borderColor: "#DEE1E3", // Default border color
          },
          "& .MuiOutlinedInput-root": {
            height: 60,
            "& fieldset": {
              transition: "border-color 0.3s",
              borderRadius: "0px",
            },
            "&:hover fieldset": {
              borderColor: "#DEE1E3",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#DEE1E3",
            },
          },
        }}
        label={label}
        id={id}
        variant={variant}
        {...props}
      />
    </div>
  );
};

export default CustomTextField;
