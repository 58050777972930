import React from "react";
import { TextField } from "@mui/material";
import CustomTextField from "./CustomTextField";
import PrimaryButtons from "./PrimaryButtons";
import { CheckboxGroup } from "@nextui-org/react";
import { Checkbox, Link, User, Chip, cn } from "@nextui-org/react";

function RFPForm() {
  const [isSelected, setIsSelected] = React.useState(false);

  return (
    <div className="bg-[#F3F7F9]  px-[20px] max-lg:pr-[20px] max-lg:pl-[20px] py-[80px] xl:px-32 2xl:px-60 3xl:px-[372px]">
      <h1 className="max-sm:max-w-[400px] uppercase text-mainB section-title lg:mt-[-7px] mb-[-7px]">
        Subscribe
      </h1>

      <div className="grid grid-cols-2 pt-[60px] mt-[-2px] gap-y-[20px] gap-x-[20px]">
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField
            label="First Name"
            id="fullWidth"
            variant="outlined"
          />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField
            label="Last Name"
            id="fullWidth"
            variant="outlined"
          />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField label="Company" id="fullWidth" variant="outlined" />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField
            label="Job Title"
            id="fullWidth"
            variant="outlined"
          />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField label="Email" id="fullWidth" variant="outlined" />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField
            label="Phone Number"
            id="fullWidth"
            variant="outlined"
          />
        </div>

        <div className="col-span-2 max-sm:col-span-2 mt-[-7px] mb-[6px]">
          <CheckboxGroup
            radius="none"
            label="RFP Service Desired"
            orientation="horizontal"
            color="primary"
            className="checkboxGroup"
            classNames={{
              label: "font-semibold Label-Color mb-[4px]",
            }}
          >
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 mt-[2px] gap-5">
              {" "}
              {/* Adjust gap size as needed */}
              {["assurance", "advisory", "risk", "tax", "technology"].map(
                (service) => (
                  <div
                    key={service}
                    className="col-span-1 bg-white p-5 border border-gray-300 rounded-none w-full"
                  >
                    <Checkbox
                      value={service}
                      size="md"
                      aria-label={
                        service.charAt(0).toUpperCase() + service.slice(1)
                      } // Capitalizes the first letter
                      classNames={{
                        base: cn(
                          "inline-flex items-center justify-start w-full rounded-none"
                        ),
                        label: "w-full Label-Color",
                        icon: "rounded-none",
                      }}
                      onValueChange={() => setIsSelected(service)} // Custom function to handle change
                    >
                      <span className="text-mainB">
                        {service.charAt(0).toUpperCase() + service.slice(1)}{" "}
                        {/* Capitalizes the first letter */}
                      </span>
                    </Checkbox>
                  </div>
                )
              )}
            </div>
          </CheckboxGroup>
        </div>
        <div className="col-span-2 max-sm:col-span-2">
          <CustomTextField
            label="RFP Deadline"
            id="fullWidth"
            variant="outlined"
          />
        </div>
        <div className="col-span-2 mt-[-6px]">
          <TextField
            fullWidth
            sx={{
              "& .MuiInputLabel-root": {
                color: "#002C54",
                height: "100%",
                marginTop: "3px",
                fontFamily: "NotoSansReg",
              },
              "& .MuiInputBase-input": {
                fontFamily: "NotoSansReg",
              },
              "& .MuiInputBase-root": {
                backgroundColor: "white",
              },
              "& label": {
                paddingLeft: (theme) => theme.spacing(1),
                color: "#002C54",
                fontWeight: 400,
                lineHeight: "20.52px",
                // marginTop: "-1px",
              },
              "& input": {
                paddingLeft: (theme) => theme.spacing(1.5),
                color: "#002C54",
              },
              "& fieldset": {
                paddingLeft: (theme) => theme.spacing(1.5),
                borderColor: "#DEE1E3", // Default border color
                // background: "white",
              },
              "& .MuiOutlinedInput-root": {
                height: 60,
                "& fieldset": {
                  transition: "border-color 0.3s",
                  borderRadius: "0px",
                },
                "&:hover fieldset": {
                  borderColor: "#DEE1E3",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#DEE1E3",
                },
              },
            }}
            label="Message"
            id="fullWidth"
            variant="outlined"
          />
        </div>
      </div>

      <div className="mt-[60px]">
        <PrimaryButtons label="Submit" />
      </div>
    </div>
  );
}

export default RFPForm;
