import React from "react";
import ServiceDetailsHeader from "../Components/ServiceDetailsSections/ServiceDetailsHeader";
import DetailsInfo from "../Components/DetailsInfo";
import ServiceDetailsSlider from "../Components/ServiceDetailsSections/ServiceDetailsSlider";
import ServiceDetailsSecondHeader from "../Components/ServiceDetailsSections/ServiceDetailsSecondHeader";
import InfoImage from "../assets/ServiceDetails/ServiceDetails.png";
import Info2Image from "../assets/ServiceDetails/ServiceDetailsSecond.png";
import Info3Image from "../assets/ServiceDetails/ServiceDetails3.png";
import Info4Image from "../assets/ServiceDetails/ServiceDetails4.png";
import ServiceDetailAccordion from "../Components/ServiceDetailsSections/ServiceDetailAccordion";
import ServiceSecondInfo from "../Components/ServiceDetailsSections/ServiceSecondInfo";
function ServicesDetails() {
  const firstData = {
    image: InfoImage,
    title: "Business Consulting",
    subtitle: "A Wide Range of Services",
    description:
      "The broad term of business consulting involves a wide range of services that help you improve your business performance and achieve your goals. ",
  };

  const secondData = {
    image: Info2Image,
    title: "Transfer Pricing Advisory",
    subtitle:
      "In the global business environment, conducting transactions among related companies is common practice, but it comes with its complexities, especially concerning transfer pricing. ",
    description:
      "At PA Global, our team of professionals understands these intricacies. We act as your trusted advisor with the knowledge and experience to navigate the dynamic world of transfer pricing. We meticulously analyze market data and apply best practices to ensure your transfer pricing methods align with the arm's length principle, minimizing tax risks and fostering long-term success in the global marketplace. Let PA Global be your partner in navigating the complexities of transfer pricing with confidence.",
  };

  const thirdData = {
    image: Info3Image,
    title: "IPR",
    subtitle:
      "PA Global, your trusted ally in protecting and maximizing the value of your intellectual assets.",
    description:
      "Safeguarding your intellectual property (IP) is essential for maintaining your competitive edge and capitalizing on your innovations. At PA Global, we recognize the critical importance of IP protection. Hence, we offer comprehensive Intellectual Property Rights (IPR) services to help you in maximizing the value of your intellectual assets. Whether it is a trademark, copyright, patent, or trade secret, we work closely with you to identify, register, and enforce your IP rights, ensuring your creations are protected.",
  };

  const fourthData = {
    image: Info4Image,
    title: "Legal Advisory",
    subtitle: "Your Trusted Advisor for Growth",
    description:
      "Whether you are undergoing growth, restructuring, or anything in between, PA Global's global network can help you maximize the value you achieve. As your trusted advisor, we will ensure you capture the most value and stay focused on your long-term goals.",
  };

  const accordionData = [
    {
      title: "Transaction services",
      description: `PA Global's transaction services help clients navigate complex business combinations. From Mergers and Acquisitions (M&A) to Joint Ventures and Divestitures, our team provides comprehensive support.  
This includes conducting thorough due diligence to assess potential risks and opportunities, negotiating favorable transaction documents that align with your objectives, and guiding you through the necessary regulatory approvals to ensure a smooth and successful outcome. 
Additionally, PA Global assists with international business reorganizations, helping you restructure your global operations for optimal efficiency. This may involve establishing subsidiaries in strategic locations, developing tax optimization strategies, and facilitating cross-border transactions to achieve your business goals.
`,
    },
    {
      title: "Governance and Compliance",
      description: `Navigating the complexities of workforce management and legal compliance in the globalized business landscape is crucial.
PA Global offers a comprehensive set of services to ensure your company operates ethically and within legal boundaries, regardless of location.  Our Entity Governance practice provides guidance on board structures, shareholder rights, and executive compensation, promoting responsible and transparent corporate leadership across your global workforce. 
Additionally, our teams assist you with your contracts and agreements with vendors, suppliers, customers, and other partners, ensuring they are clear, concise, and protect your interests worldwide.  
Furthermore, PA Global's employment laws expertise, helps you navigate complex labor regulations across international borders, manage employee relations effectively, and draft compliant employment contracts, minimizing legal risks and fostering a positive work environment for your global workforce.
`,
    },
    {
      title: "Regulatory Compliance ",
      description: `Operating in today's globalized world requires navigating a complex web of regulations that vary by jurisdiction. PA Global's regulatory compliance team helps you navigate these challenges with confidence. Our environmental law experts provide comprehensive guidance, ensuring your company operates sustainably and minimizes potential liabilities associated with environmental regulations. 
Additionally, our data privacy specialists keep you up-to-date on evolving data protection laws across different markets. We help you implement robust safeguards for customer information, protecting your brand reputation and fostering trust with your global clientele.
`,
    },
    {
      title: "PA Stands by You",
      description: `Beyond navigating transactions and ensuring sound governance, PA Global offers a comprehensive set of services to address your ongoing business challenges. 
Our Dispute Resolution team helps you navigate disagreements with partners or competitors, exploring solutions through negotiation, mediation, or litigation when necessary. 
Furthermore, our tax law specialists provide expert guidance service on tax planning, compliance strategies, and minimizing your tax burden. Finally, PA Global's transfer pricing services ensure you comply with international tax regulations for transactions between affiliated companies, minimizing potential risks and legal hurdles  and maximizing your global profitability.
`,
    },
  ];

  const slides1 = [
    {
      title: "Strategy and Management Consulting",
      description:
        "Feeling disoriented in the business landscape? Allow PA Global to collaborate with you to craft a winning business plan, analyze market trends, and develop strategic responses to navigate shifts in the market. Together, we set clear goals and create a roadmap for sustainable growth, ensuring you reach your full potential.",
    },
    {
      title: "Financial Consulting",
      description:
        "Our team provides expert financial guidance, including budgeting, forecasting, and risk management.Financial decisions impact your business journey. Let our experts assist you with budgeting, cash flow forecasting, and risk management. We help optimize resources, make informed investments, and achieve long-term stability.",
    },
    {
      title: "Human Resources Consulting",
      description:
        "Your workforce is your greatest asset. At PA Global, we help you attract and retain top talents by designing competitive compensation packages and fostering a positive work environment. Additionally, we support you in optimizing your workforce through performance management and talent development programs, maximizing your human capital advantage.",
    },
    {
      title: "Operations Consulting",
      description:
        "Is inefficiency hindering your business operations? Let PA Global be your efficiency supporter! Our operations experts help you streamline processes, eliminate waste, and leverage technology to enhance productivity. We identify bottlenecks and implement best practices to ensure your business operates smoothly and efficiently.",
    },
    {
      title: "Marketing Consulting",
      description:
        "We develop effective marketing strategies to reach your target audience and achieve your marketing goals.By partnering with PA Global, you gain access to a wealth of experience and a team dedicated to your success. We become an extension of your team, working together to help you achieve your vision.",
    },
  ];
  const slides2 = [
    {
      title: "Financial Due Diligence",
      description:
        "We conduct thorough reviews of a target company's financial records to assess its financial health and identify any potential risks or liabilities. This includes analyzing financial statements, cash flow projections, and historical financial performance to ensure accuracy and transparency.",
    },
    {
      title: "Legal Due Diligence",
      description:
        "Our team reviews legal documents such as contracts, agreements, and regulatory filings to identify any legal risks or issues that may impact the transaction. This includes assessing compliance with laws and regulations, as well as potential liabilities related to litigation or regulatory actions.",
    },
    {
      title: "Operational Due Diligence",
      description:
        "We evaluate the operational aspects of a target company, including its business processes, supply chain, and organizational structure. This involves identifying operational inefficiencies, risks, and opportunities for improvement to enhance operational performance post-acquisition.",
    },
    {
      title: "Commercial Due Diligence",
      description:
        "We assess the target company's market position, competitive landscape, and growth prospects to evaluate its commercial viability. This includes analyzing customer relationships, market trends, and competitive differentiation to assess the target company's future revenue potential and market share.",
    },
    {
      title: "Technology Due Diligence",
      description:
        "Our team evaluates the target company's technology infrastructure, systems, and intellectual property assets to assess their value and potential risks. This includes reviewing software applications, patents, and technology licenses to ensure alignment with your strategic objectives.",
    },
  ];
  const slides3 = [
    {
      title: "Firstly,",
      description:
        "Our teams have deep expertise in international tax regulations and transfer pricing best practices. We bring a wealth of knowledge and experience to the table, ensuring that your transfer pricing strategies are robust and compliant.",
    },
    {
      title: "Secondly,",
      description:
        "Our client-centric approach means that we prioritize understanding your unique business model and objectives. We tailor our strategies to fit your specific needs, ensuring that our solutions align with your goals.",
    },
    {
      title: "Thirdly,",
      description:
        "Our insights are driven by data. We use advanced methodologies to analyze market data and ensure that your transfer pricing practices adhere to the arm's length principle.",
    },
    {
      title: "Furthermore,",
      description:
        "Our services are designed to be clearly implemented. From developing a comprehensive transfer pricing policy to preparing detailed documentation. We guide you through every step in the process, ensuring clarity and compliance at every turn. With a global reach and deep understanding of international tax frameworks, our team can navigate the complexities of transfer pricing across diverse jurisdictions, ensuring compliance wherever your business operates.",
    },
    {
      title: "Transfer Pricing Services,",
      type: "List",
      description:
        "•	Policy development. \n•  In-depth studies to determine appropriate transfer prices. \n  •	Documentation support \n •	Ongoing guidance to address any challenges that may arise. \n \n By partnering with PA Global, you gain a trusted advisor dedicated to navigating the world of transfer pricing, empowering you to achieve efficiency, minimize tax risks, and ensure long-term success in the global marketplace. ",
    },
  ];
  return (
    <div className="">
      <ServiceDetailsHeader />
      <DetailsInfo data={firstData} />
      <ServiceDetailsSlider slides={slides1} />
      <ServiceDetailsSecondHeader />
      <ServiceDetailsSlider darkerVersion={true} slides={slides2} />
      <div className=" bg-mainB mt-[80px] pb-[80px]">
        <DetailsInfo data={secondData} whiteText={true} />
        <ServiceDetailsSlider
          blueVersion={true}
          includeIntro={true}
          slides={slides3}
        />
      </div>

      <DetailsInfo data={thirdData} flipped={true} />
      <DetailsInfo data={fourthData} />
      <div className="pb-[80px]">
        <ServiceDetailsSlider slides={accordionData}  />
      </div>
      <ServiceSecondInfo />
    </div>
  );
}

export default ServicesDetails;
