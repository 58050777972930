import { Divider, Image } from "@nextui-org/react";
import React from "react";
import { ReactComponent as TwitterX } from "../assets/Logos/twitter.svg";
import { ReactComponent as Youtube } from "../assets/Logos/youtube.svg";
import { ReactComponent as LinkedIn } from "../assets/Logos/linked.svg";
import { ReactComponent as Insta } from "../assets/Logos/insta.svg";
import { ReactComponent as Facebook } from "../assets/Logos/facebook.svg";
import { ReactComponent as PaLogo } from "../Components/PaLogo.svg";
import { ArrowUpIcon } from "@heroicons/react/24/outline";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="bg-[#F3F7F9] p-2 py-[40px] xl:py-[80px] xl:px-32 2xl:px-60 3xl:px-[372px]">
      <div className="flex max-lg:flex-col gap-12 xl:gap-[233px] justify-between">
        <PaLogo />
        <div className="">
          <div className="grid grid-cols-1 gap-[20px] lg:flex lg:gap-[40px] text-mainB footerlinks ">
            <a
              href="/Connect"
              className="hover:text-secondG hover:cursor-pointer navBarTitle text-mainB tm-link center"
            >
              Connect With Us
            </a>
            <p className="hover:text-secondG hover:cursor-pointer navBarTitle text-mainB tm-link center">
              Legal and Privacy
            </p>
            <p className="hover:text-secondG hover:cursor-pointer navBarTitle text-mainB tm-link center">
              Member Portal
            </p>
            <p className="hover:text-secondG hover:cursor-pointer navBarTitle text-mainB tm-link center">
              Sitemap
            </p>
          </div>
          <Divider className="my-[55px] font-bold" />
        </div>
      </div>
      <div className="flex items-center justify-between lg:justify-end max-lg:gap-[20px] gap-[40px]">
        <div className="flex items-center gap-[40px]">
          <TwitterX className="hover:text-secondG hover:cursor-pointer" />
          <Youtube className="hover:text-secondG hover:cursor-pointer" />
          <LinkedIn className="hover:text-secondG hover:cursor-pointer" />
          <Insta className="hover:text-secondG hover:cursor-pointer" />
          <Facebook className="hover:text-secondG hover:cursor-pointer" />
        </div>

        <div className="max-lg:flex hidden justify-end">
          <div
            className="border-[0.5px] border-mainB h-min p-2 w-min group hover:bg-mainB group-hover:bg-mainB hover:cursor-pointer transition-background duration-1000 ease-in-out flex items-center"
            onClick={scrollToTop}
          >
            <ArrowUpIcon className="h-6 text-xs text-mainB group-hover:text-white " />
          </div>
        </div>
      </div>
      <div className="max-lg:pt-[80px] pb-[44px] 2xl:mt-[80px] flex max-lg:flex-col-reverse ">
        <div className="text-mainB 2xl:h-[66px] flex flex-col gap-[20px] w-full">
          <div className="w-2/3 2xl:w-[711px]">
            <p className="footerText text-mainB">
              © 2024 PA All Rights Reserved <br /> <br />
            </p>
            <p className="footerText text-mainB">
              PA offers global audit, tax, and consultancy services to drive
              business success. With members across Europe, North America, GCC,
              and MENA regions, we navigate local regulations for you.
            </p>
          </div>
        </div>
        <div className="flex justify-end mt-[20px]">
          <div
            className="border-[0.5px] border-mainB h-min p-2 w-min group hover:bg-mainB group-hover:bg-mainB hover:cursor-pointer transition-background duration-1000 ease-in-out"
            onClick={scrollToTop}
          >
            <ArrowUpIcon className="h-6 text-xs text-mainB group-hover:text-white " />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
