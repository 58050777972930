import React from "react";

function StandardCard({ title, titleClass, body, className }) {
  return (
    <div
      className={` flex flex-col items-start justify-start gap-[20px] ${className}`}
    >
      <div className={`section-title uppercase ${titleClass}`}>{title}</div>
      <div className="section-paragraph w-[290px] text-start">{body}</div>
    </div>
  );
}

export default StandardCard;
