import React from "react";
import HeaderImage from "../../assets/HeadersBackground/InsightsDetailsHeader.png";
import Header from "../Header";

function CategoryHeader() {
  const title = "Artificial Intelligence";
  const description = `Discover how AI is revolutionizing business with enhanced
              efficiency, decision-making, and productivity by exploring the
              latest trends and innovations in our artificial intelligence blogs.`;

  return (
    <div>
      <Header title={title} image={HeaderImage} description={description} />
    </div>
  );
}

export default CategoryHeader;
