import React from "react";
import BorderedArrow from "./BorderedArrow";
import PrimaryButtons from "./PrimaryButtons";
function Card({ details, key, action, titleIsWhite, smallVersion , descriptionIsWhite }) {
  return (
    <div className="w-full">
      <div className={`w-full`}>
        <h1
          className={`text-xl font-bold uppercase container-title mb-[-3px] mt-[-1px] pb-[20px] overflow-hidden text-ellipsis whitespace-nowrap ${
            titleIsWhite ? "text-white" : "text-mainB"
          }`}
        >
          {details.title}
        </h1>
        <div className="group relative  w-full">
          <div className=" w-full">
            <img
              src={details.image}
              alt="artificial intelligence insight"
              className={`object-fill w-full h-full`}
            />
          </div>

          <div className="top-0 absolute h-full w-full opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in">
            <div className="absolute flex items-center justify-center h-full w-full opacity-100 z-10">
              <PrimaryButtons
                label={"Read more"}
                className={"hover:opacity-100"}
                action={action}
              />
            </div>
            <div className="bg-mainB opacity-70 h-full" />
          </div>
        </div>
        <div className="pt-[20px] flex gap-[10px] items-center">
          <BorderedArrow action={action} />
          <div className={` text-[10pt] leading-[130%] body-text ${descriptionIsWhite ? "text-white" : " text-mainB"}`}>
            {details.description}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
