import { ArrowRightIcon } from "@heroicons/react/24/outline";
import React from "react";

const noop = () => {};

function BorderedArrow({ action = noop }) {
  return (
    <div onClick={action} className="group">
      <div className="flex items-center justify-center border-[0.5pt] min-h-[40px] min-w-[40px] border-secondG group hover:bg-secondG group-hover:bg-secondG hover:cursor-pointer transition-background duration-300 ease-in-out">
        <ArrowRightIcon className="w-[24px] h-[20px] text-secondG group-hover:text-white " />
      </div>
    </div>
  );
}

export default BorderedArrow;
