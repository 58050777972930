import React, { useState } from "react";
import GeneralInformations from "./GeneralInformations";
import NumberOfPersonnel from "./NumberOfPersonnel";
import EmployeeTraining from "./EmployeeTraining";
import JurisdictionServices from "./JurisdictionServices";

function FormSteps() {
  const [activeIndex, setActiveIndex] = useState(2);

  const forms = [
    <GeneralInformations setActiveIndex={setActiveIndex} />,
    <NumberOfPersonnel setActiveIndex={setActiveIndex} />,
    <EmployeeTraining setActiveIndex={setActiveIndex} />,
    <JurisdictionServices setActiveIndex={setActiveIndex} />,
  ];
  return (
    <div className="px-[10px] pt-[80px] lg:px-5 mt-[-7px] xl:px-32 2xl:px-60 3xl:px-[372px]">
      {forms[activeIndex]}
    </div>
  );
}

export default FormSteps;
