import React from "react";
import IndustriesPageHeader from "../Components/IndustriesPageSections/IndustriesPageHeader";
import LatestNewsIndustries from "../Components/IndustriesPageSections/LatestNews";
import FeaturedIndustries from "../Components/IndustriesPageSections/FeaturedIndustries";

function IndustriesPage() {
  return (
    <div>
      <IndustriesPageHeader />
      <LatestNewsIndustries />
      <FeaturedIndustries />
    </div>
  );
}

export default IndustriesPage;
