import React from "react";
import BorderedArrow from "../BorderedArrow";
import { Divider } from "@nextui-org/react";

function IndustriesInfo({ details }) {
  return (
    <div>
      <div className="flex gap-4 lg:items-center  text-sm">
        <div className="flex gap-[20px] items-center flex-1 w-full">
          <img
            src={details.image}
            alt="ServicesInfoImg"
            className="h-[80px] w-[80px] object-cover"
          />
          <div className="flex flex-col gap-[10px] text-mainB lg:min-w-[350px]">
            <h1 className="font-bold ml-[-1px] smallNotoParagraphHeader">
              {details.title}{" "}
            </h1>
            <p className=" overflow-hidden smallNotoParagraphText ml-[-1px]">
              {details.subtitle}{" "}
            </p>
          </div>
        </div>
        <div className=" flex items-center justify-center gap-[40px] smallNotoParagraphText">
          <p className="max-lg:hidden ">{details.description}</p>
          <div className=" flex justify-end" >
            <BorderedArrow />
          </div>
        </div>
      </div>
      <Divider className="my-[20px]" />
    </div>
  );
}

export default IndustriesInfo;
