import React from "react";
import PrimaryButtons from "./PrimaryButtons";

function DetailsInfo({
  data,
  whiteText,
  flipped,
  haveButton,
  buttonData,
  action,
}) {
  const formattedDescription = data.description
    .split("\n")
    .map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  return (
    <div className="px-[10px] pt-[80px] md:px-5 lg:px-[20px] xl:px-32 2xl:px-60 3xl:px-[372px]">
      <div
        className={`flex flex-col gap-[20px] w-ful max-lg:gap-[40px] ${
          flipped ? "lg:flex-row-reverse" : " lg:flex-row "
        }`}
      >
        <div className="h-[350px] lg:h-[394px] w-full lg:w-1/2 flex  items-center justify-center ">
          <img
            src={data.image}
            alt="servicedetailsinfoimage"
            className="h-full w-full object-cover"
          />
        </div>
        <div className="lg:w-1/2 flex flex-col gap-[10px] mt-[-6px]">
          <div
            className={` ${
              whiteText ? "text-secondG" : "text-mainB"
            } uppercase section-title mt-[-1px] ml-[-2px] mb-[-5px]`}
          >
            {data.title}
          </div>
          <div className="flex flex-col gap-[10px] mt-[1px] mb-[-14px]">
            <p
              className={`section-paragraph ${
                whiteText ? "text-white" : "text-mainB"
              }`}
            >
              {data.subtitle}
            </p>
            <p
              className={`body-text ${
                whiteText ? "text-white" : "text-mainB "
              }`}
            >
              {formattedDescription}
            </p>
          </div>
          <div className="h-full w-full flex flex-col justify-end ">
            {haveButton && (
              <PrimaryButtons
                action={action}
                label={buttonData?.label}
                className={buttonData?.className}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsInfo;
