import React from "react";

export default function Header({
  title,
  image,
  description,
  textWhite = false,
  bigTitle = false,
}) {
  return (
    <div className="lg:h-[651px]">
      <div className="relative h-full">
        <div className="absolute inset-0 max-lg:hidden">
          <img
            src={image}
            alt="BackgroundImage"
            className="w-full h-full object-cover"
          />
        </div>

        <div className="relative flex flex-col flex-1 z-10 pl-[20px] pr-[20px] py-[80px] lg:pt-[248px] lg:pb-[101px] lg:px-[20px] xl:px-32 2xl:px-60 3xl:px-[372px] ">
          
          <div
            className={`uppercase  hero-title  ml-[-4px] ${
              textWhite ? "lg:text-white" : "text-mainB"
            } ${bigTitle ? "xl:w-[960px]" : "md:w-[620px]"}`}
          >
            {title}
          </div>
          <div>
            <p
              className={` xl:w-[620px] hero-paragraph mt-[-14px] mb-[-5px] pt-[20px] ${
                textWhite ? " lg:text-white " : " text-mainB "
              }`}
            >
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
