import React from "react";
import HeaderImage from "../../assets/HeadersBackground/WelcomeHeader.png";
import MobileHeaderImage from "../../assets/HeadersBackground/MobileWelcomeHeader.png"
function Header() {
  return (
    <div className="lg:h-[651px]">
      <div className="relative h-full">
        <div className="absolute inset-0 max-lg:hidden">
          <img
            src={HeaderImage}
            alt="BackgroundImage"
            className="w-full h-full object-cover"
          />
        </div>

        <div className="relative flex flex-col gap-[20px] flex-1 z-10 pl-[20px] pr-[20px] pt-[80px] lg:pt-[248px] lg:pb-[101px] lg:px-44 2xl:px-60 3xl:px-[372px]">
          <div className="-ml-1 uppercase hero-title text-mainB  md:w-[328px]">
            EXCELLENCE. <br/> PRECISION. <br/> RELIABILITY.
          </div>
          <div>
            <p className="md:w-[459px] hero-paragraph mt-[-11px]">
              Empowering businesses to navigate rapid change and solve complex
              challenges with our industry expertise.
            </p>
          </div>
        </div>
        <img
          src={MobileHeaderImage}
          alt="BackgroundImage"
          className="w-full min-h-[600] object-cover max-lg:block hidden"
        />
      </div>
    </div>
  );
}

export default Header;
