import React from "react";
import CareersHeader from "../Components/CareersSections/CareersHeader";
import WorkingAtPa from "../Components/CareersSections/WorkingAtPa";
import YourJourney from "../Components/CareersSections/YourJourney";
import Title from "../Components/Title";
import OpenPositions from "../Components/CareersSections/OpenPositions";

function Careers() {
  return (
    <div>
      <CareersHeader />
      <WorkingAtPa />
      <YourJourney />
      <OpenPositions />
    </div>
  );
}

export default Careers;
