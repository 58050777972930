import React from "react";
import NewsHeader from "../assets/NewsCover/Industries.png";
import { Image } from "@nextui-org/react";
import IndustriesFromDetails from "../Components/NewsDetailsSections/IndustriesFromDetails";
function IndustriesDetails() {
  return (
    <div className="">
      <div className="px-[20px] py-[80px] xl:px-32 2xl:px-[600px] w-full">
        <div className="flex justify-center items-center ">
          <div className="flex flex-col">
            <p className=" uppercase section-title text-mainB mt-[-6px] pb-[57px]">
              Financial Services{" "}
            </p>
            <h1 className="container-title uppercase mt-[-7px] lg:w-[718px] pb-[11px]">
              PA's Dynamic Solutions for Tomorrow’s Institutions{" "}
            </h1>
            <p className="pb-[11px] body-text lg:w-[626px]">
              The financial services sector stands at the forefront of a period
              filled with significant changes. This period is defined by the
              emergence of innovative trends and technologies that are
              transforming the landscape for financial-related industries such
              as banking, insurance, private equity, asset management, real
              estate, and construction.
            </p>
            <p className="pb-[17px] body-text lg:w-[626px]">
              Organizations are navigating through a complex environment, where
              advanced analytics, artificial intelligence, regulatory
              compliance, and a focus on customer-centric innovations present
              both challenges and opportunities.
            </p>
            <Image
              alt="NextUI hero Image"
              src={NewsHeader}
              className="rounded-none pb-[14px]"
            />
            <p className="pb-[11px] body-text lg:w-[626px]">
              At PA, we recognize the importance of financial institutions in
              this evolving landscape. Our commitment to excellence, integrity,
              and client-centric solutions positions us as a trusted partner
              ready to assist organizations in leveraging the potential of this
              transformative era. Utilizing our deep industry expertise and
              global resources, we offer customized solutions that precisely
              address the unique challenges and opportunities faced by each
              client within the financial services sector.
            </p>
            <p className="mb-[-4px] body-text lg:w-[626px]">
              Our collaborative teams deliver strategic advice, operational
              improvements, and sustainable growth strategies that create
              lasting value. Whether it is optimizing processes, enhancing
              customer experiences, or navigating regulatory changes, PA is
              dedicated to supporting financial institutions on their journey of
              tomorrow.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-mainB ">
        <IndustriesFromDetails />
      </div>
    </div>
  );
}

export default IndustriesDetails;
