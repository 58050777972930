import React from "react";
import CategoryHeader from "../Components/InsightCategorySections/CategoryHeader";
import LatestNews from "../Components/InsightCategorySections/LatestNews";
import TrendingArticles from "../Components/InsightCategorySections/TrendingArticles";

function InsightCategory() {
  return (
    <div>
      <CategoryHeader />
      <LatestNews />
      <TrendingArticles />
    </div>
  );
}

export default InsightCategory;
