// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputWrapperTextArea {
  background-color: white !important;
}

.MuiOutlinedInput-input {
  display: flex !important;
  align-items: center !important;
  padding-left: 12px !important;
  /* Adjust as needed */
  padding-top: 8px !important;
  /* Adjust as needed */
  padding-bottom: 8px !important;
  /* Adjust as needed */
  box-sizing: border-box;
}

.css-iqvony-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root {
  height: 54px !important;
}
.css-iqvony-MuiFormControl-root-MuiTextField-root .MuiInputLabel-root {
  margin-top: -1px !important;
}`, "",{"version":3,"sources":["webpack://./src/Css/TextField.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;;AAEA;EACE,wBAAwB;EACxB,8BAA8B;EAC9B,6BAA6B;EAC7B,qBAAqB;EACrB,2BAA2B;EAC3B,qBAAqB;EACrB,8BAA8B;EAC9B,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE,2BAA2B;AAC7B","sourcesContent":[".inputWrapperTextArea {\n  background-color: white !important;\n}\n\n.MuiOutlinedInput-input {\n  display: flex !important;\n  align-items: center !important;\n  padding-left: 12px !important;\n  /* Adjust as needed */\n  padding-top: 8px !important;\n  /* Adjust as needed */\n  padding-bottom: 8px !important;\n  /* Adjust as needed */\n  box-sizing: border-box;\n}\n\n.css-iqvony-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root {\n  height: 54px !important;\n}\n.css-iqvony-MuiFormControl-root-MuiTextField-root .MuiInputLabel-root {\n  margin-top: -1px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
