import React from "react";
import MapComponent from "../Components/ConnectSections/ConnectMap";
import ConnectHeader from "../Components/ConnectSections/ConnectHeader";
import ConnectForm from "../Components/ConnectSections/ConnectForm";

function Connect() {
  return (
    <div>
        <ConnectHeader />
        <ConnectForm />
        <MapComponent />
    </div>
  );
}

export default Connect;
