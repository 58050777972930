import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  AutocompleteItem,
  Card,
  CardBody,
  Input,
  Select,
  SelectItem,
  Tab,
  Tabs,
} from "@nextui-org/react";
import { TabView, TabPanel } from "primereact/tabview";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import CareerCard from "./CareerCard";

function OpenPositions() {
  const [value, setValue] = React.useState();
  const [inputText, setInputText] = useState("");
  const paginationRef = useRef(null);
  const swiperRef = useRef(null);

  const countries = [
    { key: 1, label: "Lebanon" },
    { key: 2, label: "Saudi Arabia" },
    { key: 3, label: "Egypt" },
  ];

  const careers = [
    {
      id: 1,
      Position: "Senior Risk Advisor",
      Date: "5 July",
      location: "KSA",
      Country: 2,
      responsibilities: [
        "Anticipates and interprets developments within supervision, legislation and regulations.",
        "Develops strategies, structures and processes to clients so they develop their business.",
      ],
      candidates: 122,
    },
    {
      id: 2,
      Position: "Senior Risk Advisor",
      Date: "5 July",
      location: "Beirut",
      Country: 1,
      responsibilities: [
        "Anticipates and interprets developments within supervision, legislation and regulations.",
        "Develops strategies, structures and processes to clients so they develop their business.",
      ],
      candidates: 122,
    },
    {
      id: 3,
      Position: "Senior Risk Advisor",
      Date: "5 July",
      location: "Cairo",
      Country: 3,
      responsibilities: [
        "Anticipates and interprets developments within supervision, legislation and regulations.",
        "Develops strategies, structures and processes to clients so they develop their business.",
      ],
      candidates: 122,
    },
  ];

  const tab1HeaderTemplate = (options) => {
    return (
      <div
        className="flex align-items-center gap-2 py-3 container-title"
        style={{ cursor: "pointer" }}
        onClick={options.onClick}
      >
        <span className="font-bold white-space-nowrap self-center">
          Open Positions
        </span>
        <div className="text-secondG border-[1px] flex items-center justify-center border-secondG aspect-square w-10 h-10">
          {careers.length}
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (swiperRef.current && paginationRef.current) {
      const swiper = swiperRef.current.swiper;
      swiper.params.pagination.el = paginationRef.current;
      swiper.pagination.init();
      swiper.pagination.render();
      swiper.pagination.update();
    }
  }, [swiperRef, paginationRef]);

  var size = Object.keys(careers).length;

  const [filteredCareers, setFilteredCareers] = useState(careers);
  function filterData() {
    const filteredCareers = careers.filter((career) => {
      const countryMatch = value ? career.Country == value.currentKey : true;
      const textMatch = inputText
        ? career.Position.toLowerCase().includes(inputText.toLowerCase())
        : true;
      return countryMatch && textMatch;
    });
    setFilteredCareers(filteredCareers);
  }

  useEffect(() => {
    filterData();
  }, [value, inputText]);
  return (
    <div className="relative pb-[44px] w-full">
      <div className="relative px-[20px]  xl:px-32 2xl:px-60 3xl:px-[372px] w-full grid grid-cols-2 md:grid-cols-4 justify-end items-end pt-[80px] pb-[20px] h-full gap-[20px]">
        <div></div>
        <div></div>
        <Select
          label="Country"
          variant="bordered"
          className="max-w-xs rounded-none relative"
          selectedKeys={value}
          selectionMode="single"
          onSelectionChange={setValue}
          classNames={{
            trigger: "rounded-none shadow-none",
            popoverContent: "rounded-none",
          }}
        >
          {countries.map((country) => (
            <SelectItem key={country.key} className="rounded-none">
              {country.label}
            </SelectItem>
          ))}
        </Select>
        <div className="h-full">
          <Input
            isClearable
            type="search"
            placeholder="Search"
            variant="bordered"
            className="max-w-xs h-full shadow-none"
            value={inputText}
            onValueChange={setInputText}
            endContent={
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.1442 10.9041L12.6028 11.4823L11.7115 10.6728C12.6361 9.54933 13.1942 8.11198 13.1942 6.54245C13.1942 2.93254 10.2455 0 6.59711 0C2.95704 0 0 2.92428 0 6.54245C0 10.1524 2.94871 13.0849 6.59711 13.0849C8.37133 13.0849 9.98729 12.391 11.1701 11.2593L12.0614 12.0688L11.5199 12.6471L17.384 18L19 16.257L13.1442 10.9041ZM6.59711 12.3001C3.39851 12.3001 0.79965 9.72281 0.79965 6.55071C0.79965 3.37861 3.39851 0.801285 6.59711 0.801285C9.7957 0.801285 12.3946 3.37861 12.3946 6.55071C12.3946 9.72281 9.7957 12.3001 6.59711 12.3001ZM17.3341 16.8848L12.6445 12.6058L13.1859 12.0275L17.8755 16.3066L17.3341 16.8848Z"
                  fill="#A5A5A5"
                />
              </svg>
            }
            classNames={{
              inputWrapper: "rounded-none h-full shadow-none",
              mainWrapper: "h-full",
            }}
          />
        </div>
      </div>

      <div className="relative flex flex-wrap gap-4 w-full">
        <div className="relative tabs w-full">
          <TabView className="relative w-full max-xl:px-[20px]">
            <TabPanel
              header="Header I"
              className=""
              headerTemplate={tab1HeaderTemplate}
            >
              <Swiper
                ref={swiperRef}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  750: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 18,
                  },
                }}
                pagination={{
                  el: paginationRef.current,
                  clickable: true,
                  renderBullet: (index, className) => {
                    return `<span class="${className} border-1"></span>`;
                  },
                }}
                onSwiper={(swiper) => {
                  if (
                    swiper.params.pagination.el &&
                    swiper.pagination &&
                    swiper.pagination.render &&
                    swiper.pagination.init
                  ) {
                    swiper.pagination.el = paginationRef.current;
                    swiper.pagination.render();
                    swiper.pagination.init();
                  }
                }}
                className="mySwiper mt-[40px]"
                modules={[Pagination]}
              >
                {filteredCareers.map((career, index) => (
                  <SwiperSlide
                    key={career.id}
                    style={
                      window.innerWidth > 1024
                        ? size === 2
                          ? { width: "50%" }
                          : {
                              width: 372,
                              minWidth: 372,
                              maxWidth: 372,
                              height: 370,
                              minHeight: 370,
                              maxHeight: 370,
                            }
                        : { width: "100%" }
                    }
                  >
                    <CareerCard data={career} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </TabPanel>
          </TabView>
          <div className="custom-pagination" ref={paginationRef}></div>
        </div>
      </div>
    </div>
  );
}

export default OpenPositions;
