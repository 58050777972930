import { Link, useLocation } from "react-router-dom";
import MenuItem from "./MenuItem";
import React, { useState } from "react";
import { motion } from "framer-motion";

import { ReactComponent as MessageIcon } from "../assets/SVG/message.svg";
import { ReactComponent as PersonIcon } from "../assets/SVG/person.svg";
import { ReactComponent as WorldIcon } from "../assets/SVG/world.svg";
import { ReactComponent as SearchIcon } from "../assets/SVG/search.svg";
import { ReactComponent as PaLogo } from "../Components/PaLogo.svg";

/* Insights Featured Images */
import AIPic from "../assets/Insights/AI-Insight.png";
import ESGPic from "../assets/Insights/ESG-Insight.png";
import RISKPic from "../assets/Insights/RISK-Insight.png";
import DIGPic from "../assets/Insights/DIG-Insight.png";
import TAXPic from "../assets/Insights/TAX-Insight.png";
import REPPic from "../assets/Insights/REP-Insight.png";
import FINPic from "../assets/Insights/FIN-Insight.png";
import STRPic from "../assets/Insights/STR-Insight.png";

/* Industries Featured Images */
import FinancialPIC from "../assets/Industries/Financial.png";
import ConsumerPIC from "../assets/Industries/Consumer.png";
import HospitalityPIC from "../assets/Industries/Hospitality.png";
import HealthPIC from "../assets/Industries/HealthCare.png";
import EnergyPIC from "../assets/Industries/Energy.png";
import TechnologyPIC from "../assets/Industries/Technology.png";
import IndustrialPIC from "../assets/Industries/Industrial.png";
import TransportPIC from "../assets/Industries/Transport.png";
import PublicPIC from "../assets/Industries/Public.png";

/* Services Featured Images */
import Assurance from "../assets/ServicesCategory/Insights blog link.png";
import Advisory from "../assets/ServicesCategory/Rectangle 12.png";
import Risk from "../assets/ServicesCategory/Rectangle 16 (3).png";
import Tax from "../assets/ServicesCategory/Rectangle 16 (4).png";
import Technology from "../assets/ServicesCategory/Rectangle 17 (3).png";

const MegaMenu = ({ handleToggle, clicked, setIsDrawerOpen }) => {
  const location = useLocation();

  const menuData = [
    {
      label: "Insights",
      href: "/Insights",
      FeaturedImage: AIPic,
      labelDesc: `Explore our Insights for strategic views on business model innovation, climate action, sustainability, and trust in technology's fast-paced landscape.`,
      children: [
        {
          heading: "Artificial Intelligence",
          href: "/InsightCategory",
          menuLabel: "Insights",
          FeaturedDesc:
            "Explore AI's role in boosting business efficiency, decision-making, and productivity.",
          FeaturedImage: AIPic,
        },
        {
          heading: "Esg And Sustainability",
          href: "/InsightCategory",
          menuLabel: "Insights",
          FeaturedDesc:
            "Understand how ESG and sustainability drive business growth and innovation.",
          FeaturedImage: ESGPic,
        },
        {
          heading: "Risk Management",
          href: "/InsightCategory",
          menuLabel: "Insights",
          FeaturedDesc:
            "Learn to balance risk to safeguard and advance your business.",
          FeaturedImage: RISKPic,
        },
        {
          heading: "Digital Adoption and Transformation",
          href: "/InsightCategory",
          menuLabel: "Insights",
          FeaturedDesc:
            "Boost business efficiency and innovation through digital transformation.",
          FeaturedImage: DIGPic,
        },
        {
          heading: "Tax and Regulatory Change",
          href: "/InsightCategory",
          menuLabel: "Insights",
          FeaturedDesc:
            "Navigate tax and regulatory changes with strategic insights.",
          FeaturedImage: TAXPic,
        },
        {
          heading: "Reporting Standards",
          href: "/InsightCategory",
          menuLabel: "Insights",
          FeaturedDesc:
            "Stay compliant with updates on international financial reporting standards.",
          FeaturedImage: REPPic,
        },
        {
          heading: "Finance and Assurance",
          href: "/InsightCategory",
          menuLabel: "Insights",
          FeaturedDesc:
            "Enhance financial stability and integrity through optimized management.",
          FeaturedImage: FINPic,
        },
        {
          heading: "Strategy",
          href: "/InsightCategory",
          menuLabel: "Insights",
          FeaturedDesc:
            "Craft adaptive strategies for growth and alignment with economic trends.",
          FeaturedImage: STRPic,
        },
      ],
    },
    {
      label: "Services",
      href: "/Services",
      labelDesc:
        "Our Business Insights section offers executives clear strategies on reshaping business models, addressing sustainability, and building trust amid technological change.",
      children: [
        {
          heading: "Assurance",
          href: "/Services",
          menuLabel: "Services",
          FeaturedImage: Assurance,
          FeaturedDesc: `Enhancing information quality and reducing risk with independent expertise.`,
        },
        {
          heading: "Advisory",
          href: "/Services",
          menuLabel: "Services",
          FeaturedDesc: "Empowering informed decisions for long-term success.",
          FeaturedImage: Advisory,
        },
        {
          heading: "Risk",
          href: "/Services",
          menuLabel: "Services",
          FeaturedDesc:
            "Strategic risk management for resilience and positioning.",
          FeaturedImage: Risk,
        },
        {
          heading: "Tax",
          href: "/Services",
          menuLabel: "Services",
          FeaturedDesc: "Balancing tax laws and business strategies.",
          FeaturedImage: Tax,
        },

        {
          heading: "Technology",
          href: "/Services",
          menuLabel: "Services",
          FeaturedDesc:
            "Streamlined solutions to reduce risks and drive transformation.",
          FeaturedImage: Technology,
        },
      ],
    },
    {
      label: "Industries",
      href: "/Industries",
      FeaturedImage: FinancialPIC,
      labelDesc: `In today's industry, AI and blockchain are transforming business. At PA, we use our expertise to turn these changes into opportunities for growth.`,
      children: [
        {
          heading: "Financial Services",
          href: "/IndustriesCategory",
          menuLabel: "Industries",
          FeaturedDesc:
            "PA helps financial institutions optimize processes and enhance customer experiences.",
          FeaturedImage: FinancialPIC,
        },
        {
          heading: "Consumer and Retail",
          href: "/IndustriesCategory",
          menuLabel: "Industries",
          FeaturedDesc:
            "Our custom solutions for Consumer and Retail sectors keep your business ahead.",
          FeaturedImage: ConsumerPIC,
        },
        {
          heading: "Hospitality and Leisure",
          href: "/IndustriesCategory",
          menuLabel: "Industries",
          FeaturedDesc:
            "PA sets new benchmarks in hospitality satisfaction and operations.",
          FeaturedImage: HospitalityPIC,
        },
        {
          heading: "Pharmaceuticals And Healthcare",
          href: "/IndustriesCategory",
          menuLabel: "Industries",
          FeaturedDesc:
            "PA helps financial institutions optimize processes and enhance customer experiences.",
          FeaturedImage: HealthPIC,
        },
        {
          heading: "Energy and Environment",
          href: "/IndustriesCategory",
          menuLabel: "Industries",
          FeaturedDesc:
            "PA transforms Energy and Environment with tailored sustainability solutions.",
          FeaturedImage: EnergyPIC,
        },
        {
          heading: "Technology and Telecommunications",
          href: "/IndustriesCategory",
          menuLabel: "Industries",
          FeaturedDesc: "PA helps financial institutions optimize processes",
          FeaturedImage: TechnologyPIC,
        },
        {
          heading: "Industrial",
          href: "/IndustriesCategory",
          menuLabel: "Industries",
          FeaturedDesc: "PA helps financial institutions optimize processes",
          FeaturedImage: IndustrialPIC,
        },
        {
          heading: "Transport and Logistics",
          href: "/IndustriesCategory",
          menuLabel: "Industries",
          FeaturedDesc: "PA helps financial institutions optimize processes",
          FeaturedImage: TransportPIC,
        },
        {
          heading: "Public and Social Sector",
          href: "/IndustriesCategory",
          menuLabel: "Industries",
          FeaturedDesc: "PA helps financial institutions optimize processes",
          FeaturedImage: PublicPIC,
        },
      ],
    },
    { label: "Careers", href: "/Careers" },
    {
      label: "About Us",
      href: "/AboutUs",
    },
  ];

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  const handleMenuToggle = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };
  const text = "Connect".split(" ");
  return (
    <div className="nav__container flex justify-between items-center max-lg:hidden h-[80px]  px-[40px] xl:px-32 2xl:px-60 3xl:px-[372px] w-full bg-white  ">
      <Link to={"/"} className="">
        <div className="flex">
          <PaLogo />
        </div>
      </Link>
      <nav className="w-full ml-[131px] h-full">
        <ul className="flex gap-[40px] w-full h-full">
          {menuData.map((menuItem, index) => {
            const isActive = location.pathname === menuItem.href;
            return (
              <MenuItem
                key={index}
                id={index}
                label={menuItem.label}
                href={menuItem.href}
                children={menuItem.children}
                FeaturedImage={menuItem.FeaturedImage}
                FeaturedDesc={menuItem.FeaturedDesc}
                labelDesc={menuItem.labelDesc}
                setIsDrawerOpen={() => setOpenDropdownIndex(null)}
                onToggle={() => handleMenuToggle(index)}
                active={isActive || openDropdownIndex === index}
              />
            );
          })}
        </ul>
      </nav>
      <div className="">
        <div className="flex gap-[39px] items-center justify-end flex-1">
          <Link to={"/connect"} className="group ">
            <MessageIcon className="w-5 group-hover:hidden hover:text-secondG hover:cursor-pointer" />
            <motion.span
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="hidden group-hover:block text-secondG"
            >
              Connect
            </motion.span>
          </Link>
          <PersonIcon className="w-5 max-[960px]:hidden hover:text-secondG hover:cursor-pointer" />
          <WorldIcon className="w-5 max-[960px]:hidden hover:text-secondG hover:cursor-pointer" />
          <SearchIcon className="w-5 hover:text-secondG hover:cursor-pointer" />
        </div>
      </div>
    </div>
  );
};

export default MegaMenu;
