import React from "react";
import Title from "../Title";
import Card from "../Card";
import AIPic from "../../assets/Insights/AI-Insight.png";
import ESGPic from "../../assets/Insights/ESG-Insight.png";
import RISKPic from "../../assets/Insights/RISK-Insight.png";
import Explore from "../Explore";
import { useNavigate } from "react-router-dom";

function InsightsSection() {
  let navigate = useNavigate();
  const cards = [
    {
      title: "Artificial Intelligence",
      description:
        "At PA, our forward-thinking approach empowers clients to navigate complexity.",
      image: AIPic,
    },
    {
      title: "ESG AND SUSTAINABILITY",
      description:
        "Embracing sustainability's diversity, we guide your evolving ESG journey.",
      image: ESGPic,
    },
    {
      title: "RISK MANAGEMENT",
      description:
        "PA provides essential risk management solutions.",
      image: RISKPic,
    },
  ];
  function navigateToInsights() {
    navigate("/Insights");
  }
  function navigateToInsightCategory() {
    navigate("/InsightCategory");
  }
  return (
    <div className=" px-[20px] max-lg:pr-[20px] max-lg:pl-[20px] py-[80px] xl:px-32 2xl:px-60 3xl:px-[372px]">
      <div className="flex flex-col gap-[60px] justify-start ">
        <div className="text-start mt-[-8px] ">
          <Title title={"Insights"} className={"-ml-1"} />
          <div className="text-mainB section-paragraph pt-[6px] pb-[2px]">
            Explore our diverse business insights for <br/>a forward-looking strategy
            and exponential growth.
          </div>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:flex xl:flex-shrink mt-[-9px] gap-[20px] items-center justify-center">
          {cards.map((card, index) => (
            <Card
              details={card}
              key={index}
              action={navigateToInsightCategory}
            />
          ))}
        </div>
      </div>
      <div className="mt-[60px] flex ">
        <Explore buttonLabel={"View Insights"} action={navigateToInsights} />
      </div>
    </div>
  );
}

export default InsightsSection;
