import React from "react";
import HeaderImage from "../../assets/HeadersBackground/ServicesCategory.png";

function ServiceDetailsHeader() {
  return (
    <div className="relative lg:h-[651px] max-lg:p-1">
      <div className="absolute max-xl:hidden h-full w-full">
        <img
          src={HeaderImage}
          alt="BackgroundImage"
          className="h-full w-full"
        />
      </div>

      <div className="relative flex flex-col gap-[20px] flex-1 z-10 pl-[20px] pr-[20px] py-[80px] lg:pt-[248px] lg:pb-[101px] lg:px-[20px] xl:px-32 2xl:px-60 3xl:px-[372px] ">
        <div className="uppercase  text-mainB hero-title">
          <p>advisory</p>
        </div>
        <div className="container-title">
          Refracting the Complexities of the Business World
        </div>
        <div>
          <p className="xl:w-[600px] body-text">
            Just as the prism enhances the clarity of the light passing through
            it, the PA advisory services enhance the clarity of decision-making
            for organizations, empowering them to make informed choices that
            contribute to their long-term success. The comprehensive application
            of advisory services spans multifaceted domains, providing a
            strategic direction in the ever-evolving business landscape.
            <br /> <br />
            By enabling organizations to perceive opportunities and threats from
            various angles, PA offers the decision-makers a holistic perspective
            advisory service.
          </p>
        </div>
      </div>
    </div>
  );
}
export default ServiceDetailsHeader;
