// src/MapComponent.jsx
import React, { useEffect, useRef } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import Title from "../Title";
import CountryCard from "./CountryCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
const geoUrl = "/world-110m.json";

// For the coordinates on the map
const countries = [
  {
    name: "Lebanon",
    coordinates: [-105.7129, 47.0902],
    address: "Radwan Center, 1st Floor",
    phoneNb: "961-1-341541",
  },
  {
    name: "Saudi Arabia",
    coordinates: [21.0936, 7.1881],
    address: "Dammam",
    phoneNb: "+966-13-8051000",
  },
  {
    name: "Saudi Arabia",
    coordinates: [105.3188, 61.524],
    address: "Riyadh",
    phoneNb: "New Alseteen Aqarea. Riyadh.",
  },
  {
    name: "Egypt ",
    coordinates: [104.1954, 35.8617],
    address: "YG Auditors",
    phoneNb: "+20 2516 58 10",
  },
];

// For the slider on the map
const SliderCountries = [
  {
    country: "United Arab Emirates",
    address: "Deira, Makateb Building, Office # 310. Dubai.",
    phoneNb: ["+971 4 221 1102", "+971 4 221 1103"],
    mobile: ["+971-50-2747002", "+971-50-3230602"],
    email: "info@pa-global.com",
  },
  {
    country: "United Arab Emirates",
    address:
      "Al Muroor Street, 15, Mohamed Bin Khalifa Signal, floor 4. Abu Dhabi",
    phoneNb: [],
    mobile: ["+971-50-3230602"],
    email: "info@pa-global.com",
  },
  {
    country: "Kuwait",
    address: "Shouhadaa Street, Sidik Bldg. Kuwait.",
    phoneNb: ["+965 6 6944686"],
    mobile: [],
    email: "info@pa-global.com",
  },
  {
    country: "Qatar",
    address: "27 Al-Diwan Street. Doha.",
    phoneNb: ["+974 4 355100"],
    mobile: ["+974 5563163"],
    fax: ["+974 4 372350"],
    email: "info@pa-global.com",
  },
  {
    country: "Syria",
    address: "Shahbandar Square. Omar Moukhtar Str. Damascus.",
    phoneNb: ["+9631144681821"],
    mobile: ["+963 933212369"],
    fax: ["+9631144681820"],
    email: "info@pa-global.com",
  },
  {
    country: "Jordan",
    address: "191 Khaled Ibn El Walid Str. Amman.",
    phoneNb: ["+962 6 5606709"],
    fax: ["+962 6 5653501"],
    email: "",
  },
  {
    country: "Sudan",
    address: "Parliament Str., Near Nile Bank, Wingate Bldg. Khartoum",
    phoneNb: ["+249-183-777676", "+249-183-774220"],
    mobile: ["+249-912-132997"],
    fax: ["+249-183-787992"],
    email: "info@pa-global.com",
  },
  {
    country: "Tunis",
    address: "92, Rue 8600-La Charguia 1-2035. Tunis.",
    phoneNb: ["+216-71 771212"],
    mobile: ["+216-22 312640"],
    fax: ["+216-71 771044"],
    email: "info@pa-global.com",
  },
];

const MapComponent = () => {
  const paginationRef = useRef(null);
  const swiperRef = useRef(null);
  useEffect(() => {
    if (swiperRef.current && paginationRef.current) {
      const swiper = swiperRef.current.swiper;
      swiper.params.pagination.el = paginationRef.current;
      swiper.pagination.init();
      swiper.pagination.render();
      swiper.pagination.update();
    }
  }, [swiperRef, paginationRef]);
  return (
    <div className=" px-[10px] pt-[80px] ml-[-2px] mt-[-67px] ">
      <div className=" ml-[-10px] lg:px-5 xl:px-32 2xl:px-60 3xl:px-[372px]">
        <Title title={"Our locations"} className={"lg:mb-[-119px]"} />
        <div className="">
          <ComposableMap
            projectionConfig={{
              rotate: [-10, 0, 0],
              scale: 147,
            }}
            style={{ width: "100%", height: "100%" }}
          >
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    style={{
                      default: {
                        fill: "#002E6E",
                        stroke: "none", // Remove borders
                        strokeWidth: 0,
                        outline: "none",
                      },
                      hover: {
                        fill: "#002E6E",
                        stroke: "none", // Remove borders
                        strokeWidth: 0,
                        outline: "none",
                      },
                      pressed: {
                        fill: "#002E6E",
                        stroke: "none", // Remove borders
                        strokeWidth: 0,
                        outline: "none",
                      },
                    }}
                  />
                ))
              }
            </Geographies>
            {countries.map(({ name, coordinates }) => (
              <Marker
                key={name}
                coordinates={coordinates}
                className="text-white"
              >
                <svg
                  fill="currentColor"
                  width="24px"
                  height="24px"
                  viewBox="-4 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="xMidYMid"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path d="M12.649,31.760 C12.463,31.919 12.231,31.999 12.000,31.999 C11.769,31.999 11.537,31.919 11.351,31.760 C10.887,31.365 0.000,21.976 0.000,11.964 C0.000,5.363 5.383,-0.006 12.000,-0.006 C18.617,-0.006 24.000,5.363 24.000,11.964 C24.000,21.976 13.113,31.365 12.649,31.760 ZM12.000,1.989 C6.486,1.989 2.000,6.464 2.000,11.964 C2.000,19.724 9.687,27.493 12.000,29.654 C14.312,27.493 22.000,19.724 22.000,11.964 C22.000,6.464 17.514,1.989 12.000,1.989 ZM12.000,17.991 C8.691,17.991 6.000,15.306 6.000,12.006 C6.000,8.705 8.691,6.021 12.000,6.021 C15.309,6.021 18.000,8.705 18.000,12.006 C18.000,15.306 15.309,17.991 12.000,17.991 ZM12.000,8.016 C9.794,8.016 8.000,9.805 8.000,12.006 C8.000,14.206 9.794,15.996 12.000,15.996 C14.206,15.996 16.000,14.206 16.000,12.006 C16.000,9.805 14.206,8.016 12.000,8.016 Z"></path>{" "}
                  </g>
                </svg>
              </Marker>
            ))}
          </ComposableMap>
        </div>
      </div>
      <div className="relative lg:px-5 xl:pl-32 2xl:pl-60 3xl:pl-[372px] pb-[80px] mt-[-109px]">
        <Swiper
          ref={swiperRef}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            750: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          pagination={{
            el: paginationRef.current,
            clickable: true,
            renderBullet: (index, className) => {
              return `<span class="${className} border-1"></span>`;
            },
          }}
          onSwiper={(swiper) => {
            if (
              swiper.params.pagination.el &&
              swiper.pagination &&
              swiper.pagination.render &&
              swiper.pagination.init
            ) {
              swiper.pagination.el = paginationRef.current;
              swiper.pagination.render();
              swiper.pagination.init();
            }
          }}
          className="mySwiper mt-[40px]"
          modules={[Pagination]}
        >
          {SliderCountries.map((office, index) => (
            <SwiperSlide key={index} style={{ width: "450px", minWidth: "450px",maxWidth: "450px" }}>
              <CountryCard
                key={index}
                country={office.country}
                address={office.address}
                phoneNb={office.phoneNb}
                mobile={office.mobile}
                fax={office.fax}
                email={office.email}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="custom-pagination higher-pagination"
          ref={paginationRef}
          style={{ bottom: "40px" }}
        ></div>
      </div>
    </div>
  );
};

export default MapComponent;
