import React from "react";
import NewsHeader from "../../assets/NewsCover/Insights blog link.png";
import BorderedArrow from "../BorderedArrow";
import { Link } from "react-router-dom";
import PrimaryButtons from "../PrimaryButtons";

function LatestNews() {
  return (
    <div className="px-[20px] pt-[80px] xl:px-32 2xl:px-60 3xl:px-[372px] mt-[-5px] w-full">
      <div className="flex justify-center gap-2 items-center w-full">
        <div className="w-full">
          <p className=" uppercase section-title text-mainB mt-[-2px]">
            latest news
          </p>
          <div className="group relative h-full w-full  mt-[-7px] pt-[20px]">
            <img
              src={NewsHeader}
              className="rounded-none w-full h-full object-contain"
              alt="NextUI hero "
            />
            <div className="top-0 absolute h-full w-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in  pt-[20px]">
              <div className="absolute flex items-center justify-center h-full w-full opacity-100 z-10">
                <Link
                  to={"/InsightDetails"}
                  className="flex flex-col items-center justify-center w-full "
                >
                  <PrimaryButtons
                    label={"Read more"}
                    className={"hover:opacity-100"}
                  />
                </Link>
              </div>
              <div className="bg-mainB opacity-70 h-full" />
            </div>
          </div>

          <div className="flex items-center pt-[20px] gap-[19px]">
            <Link to={"/InsightDetails"}>
              <BorderedArrow />
            </Link>

            <p className="body-text">
              STEM Education: Building the Workforce of the Future
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LatestNews;
