import React from "react";
import Title from "../Title";
import ServicesInfo from "../ServicesInfo";
import AssurancePic from "../../assets/Services/Assurance-Services.png";
import AdvisoryPic from "../../assets/Services/Advisory-Services.png";
import RiskPIC from "../../assets/Services/Risk-Services.png";
import TaxPIC from "../../assets/Services/Tax-Services.png";
import TechPIC from "../../assets/Services/Technology-Services.png";
import Explore from "../Explore";
function Services() {
  const info = [
    {
      title: "Assurance",
      subtitle: "Directing the Landscape of Decisions.",
      description:
        "Assurance services in finance provide independent expertise to improve the quality of information and reduce information risk for decision-makers.",
      image: AssurancePic,
    },
    {
      title: "Advisory",
      subtitle: "Refracting the Complexities of the Business World.",
      description:
        "PA advisory services, like a prism clarifying light, empower organizations to make informed decisions for long-term success.",
      image: AdvisoryPic,
    },
    {
      title: "Risk",
      subtitle: "Risk Through the Lens of PA Services.",
      description:
        "In PA, strategic risk management is vital amidst financial complexities, enhancing your organization's resilience and positioning.",
      image: RiskPIC,
    },
    {
      title: "Tax",
      subtitle: "Navigating Complexities with Precision.",
      description:
        "In complex tax landscapes, expert advisors are vital. We excel in balancing tax laws & business strategies.",
      image: TaxPIC,
    },
    {
      title: "Technology",
      subtitle: "Innovative Solutions Enhancing Technology.",
      description:
        "As businesses go digital, technology plays a dual role. PA offers streamlined solutions to reduce risks and drive transformation.",
      image: TechPIC,
    },
  ];
  return (
    <div className="bg-[#F3F7F9] px-[20px] max-lg:pr-[20px] max-lg:pl-[20px] py-[80px] xl:px-32 2xl:px-60 3xl:px-[372px]">
      <div className="flex flex-col lg:items-center  ">
        <div className="justify-start lg:items-center w-full  pb-[55px] my-[-5px] mb-[-2px]">
          <Title title={"Services"} className={"mt-[-2px]"}/>
        </div>
        <div>
          {info.map((item, index) => (
            <ServicesInfo details={item} key={index} />
          ))}
        </div>
      </div>
      <div className="pt-[40px]">
        <Explore buttonLabel={"View Services"} />
      </div>
    </div>
  );
}

export default Services;
