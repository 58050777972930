import { NavLink, useLocation } from "react-router-dom";
import Container from "./Container";
import DropdownContent from "./DropdownContent";
import { ChevronDown, ChevronUp } from "lucide-react";
import React, { useEffect, useState, useRef } from "react";

const MenuItem = ({
  label,
  href,
  children,
  onToggle,
  active,
  FeaturedDesc,
  FeaturedImage,
  setIsDrawerOpen,
  labelDesc,
}) => {
  const [isActive, setIsActive] = useState(active);
  const dropdownTimer = useRef(null);
  const location = useLocation();

  const handleMouseEnter = () => {
    if (dropdownTimer.current) {
      clearTimeout(dropdownTimer.current);
    }
    dropdownTimer.current = setTimeout(() => {
      setIsActive(true);
      onToggle();
    }, 50); // Adjust the timeout duration as needed
  };

  const handleMouseLeave = () => {
    if (dropdownTimer.current) {
      clearTimeout(dropdownTimer.current);
    }
    dropdownTimer.current = setTimeout(() => {
      setIsActive(false);
      setIsDrawerOpen();
    }, 50); // Short delay to prevent flickering
  };

  const handleLinkClick = () => {
    setIsDrawerOpen();
  };

  useEffect(() => {
    // Close the dropdown when the URL changes
    setIsActive(false);
  }, [location]);

  useEffect(() => {
    return () => {
      if (dropdownTimer.current) {
        clearTimeout(dropdownTimer.current);
      }
    };
  }, []);

  return (
    <li
      className={`h-full cursor-pointer flex items-center ${
        isActive ? "active-menu-item" : ""
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="nav_item_content navBarTitle tm-link">
        <NavLink
          to={href}
          className={`nav-link ${isActive ? "activeLink" : ""}`}
          onClick={handleLinkClick}
        >
          <div className="text-mainB">{label}</div>
        </NavLink>
        {children && (
          <button
            className="md:hidden"
            onClick={() => setIsActive(!isActive)}
            aria-label="Toggle dropdown"
            aria-haspopup="menu"
            aria-expanded={isActive ? "true" : "false"}
          >
            {isActive ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </button>
        )}
      </div>
      {children && (
        <div
          role="menu"
          className={`bg-mainB dropdown ${isActive ? "visible-dropdown" : ""}`}
        >
          <Container>
            <DropdownContent
              label={label}
              href={href}
              labelDesc={labelDesc}
              FeaturedDesc={FeaturedDesc}
              FeaturedImage={FeaturedImage}
              submenuscontent={children}
              setIsDrawerOpen={setIsDrawerOpen}
              setIsActive={setIsActive}
              handleClick={handleLinkClick}
            />
          </Container>
        </div>
      )}
    </li>
  );
};

export default MenuItem;
