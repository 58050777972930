import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "../src/Css/ButtonAnimations.css";
import "../src/Css/TabsCss.css";
import "../src/Css/TextField.css";
import "../src/Css/CheckBox.css";
import "../src/Css/DateInput.css";
import "../src/Css/Accordion.css";

import "../src/Css/MegaMenu.css";
import NavBar from "./Components/NavBar";
import Welcome from "./Pages/Welcome";
import Footer from "./Components/Footer";
import Insights from "./Pages/Insights";
import InsightCategory from "./Pages/InsightCategory";
import NewsDetails from "./Pages/NewsDetails";
import { useEffect } from "react";
import ServicesCategory from "./Pages/ServicesCategory";
import ServicesDetails from "./Pages/ServicesDetails";
import Industries from "./Pages/Industries";
import IndustriesPage from "./Pages/IndustriesPage";
import IndustriesDetails from "./Pages/IndustriesDetails";
import Careers from "./Pages/Careers";
import JobDetails from "./Pages/JobDetails";
import MegaMenu from "./Components/MegaMenu";
import MegaMenuComp from "./Components/MegaMenu";
import AboutUs from "./Pages/AboutUs";
import Connect from "./Pages/Connect";
import BecomeAMemberFirmForm from "./Pages/BecomeAMemberFirmForm";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="NotoBodyText ">
        <NavBar />
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/Insights" element={<Insights />} />
          <Route path="/InsightCategory" element={<InsightCategory />} />
          <Route path="/InsightDetails" element={<NewsDetails />} />
          <Route path="/Services" element={<ServicesCategory />} />
          <Route path="/ServiceDetails" element={<ServicesDetails />} />
          <Route path="/Industries" element={<Industries />} />
          <Route path="/IndustriesCategory" element={<IndustriesPage />} />
          <Route path="/IndustriesDetails" element={<IndustriesDetails />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/JobDetails" element={<JobDetails />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Connect" element={<Connect />} />
          <Route path="/BecomeAMemberFirm" element={<BecomeAMemberFirmForm />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
