import React, { useRef, useState } from "react";
import PrimaryButtons from "../PrimaryButtons";
import { TextField } from "@mui/material";
import CustomTextField from "../CustomTextField";
import { Input, Textarea } from "@nextui-org/react";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";

function ApplyPosition() {
  const fileInputRef = useRef(null);
  const [fileUploaded, setFileUploaded] = useState(null);

  const handleDivClick = () => {
    // When the div is clicked, trigger the file input click
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    // Get the selected file
    const file = event.target.files[0];
    setFileUploaded(file);
  };
  return (
    <div className="bg-[#F3F7F9]  px-[20px] max-lg:pr-[20px] max-lg:pl-[20px] py-[80px] xl:px-32 2xl:px-60 3xl:px-[372px]">
      <h1 className="max-sm:max-w-[400px] uppercase text-mainB  section-title my-[-7px]">
        Apply for this position
      </h1>

      <div className="grid  pt-[60px] mt-[-2px] gap-y-[20px] gap-x-[20px] ">
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField
            label="First Name"
            id="fullWidth"
            variant="outlined"
          />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField
            label="Last Name"
            id="fullWidth"
            variant="outlined"
          />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField
            label="Phone Number"
            id="fullWidth"
            variant="outlined"
          />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <CustomTextField label="Email" id="fullWidth" variant="outlined" />
        </div>
        <div className="col-span-1 max-sm:col-span-2">
          <div
            onClick={handleDivClick}
            className={`h-[60px] border-1 bg-white flex items-center pl-[20px] cursor-pointer ${fileUploaded ? "text-secondG" : "text-mainB"}`}
          >
            <ArrowUpTrayIcon className="w-5 h-5 mr-[10px]" />
            {fileUploaded ? "Document Uploaded" : "Upload Document"}
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
            multiple // Remove this line if you want to restrict to a single file
          />
          <p className="text-secondG text-xs mt-1 mb-[-4px] pl-[10px]">
            {fileUploaded
              ? fileUploaded.name
              : "(File types: pdf, doc, docx, txt, rtf)"}
          </p>
        </div>
        <div>
          <CustomTextField
            type={"url"}
            label="Linked In Profile"
            id="fullWidth"
            variant="outlined"
          />
        </div>
        <div className="col-span-1 max-sm:col-span-2 flex items-center"></div>
        <div className="col-span-2 mt-[-19px]">
          <div className="col-span-2 mt-[-6px]">
            <TextField
              fullWidth
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#002C54",
                  height: "100%",
                  marginTop: "3px",
                  fontFamily: "NotoSansReg",
                },
                "& .MuiInputBase-input": {
                  fontFamily: "NotoSansReg",
                  height: "100px"
                },
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                },
                "& label": {
                  paddingLeft: (theme) => theme.spacing(1),
                  color: "#002C54",
                  fontWeight: 400,
                  lineHeight: "20.52px",
                  // marginTop: "-1px",
                },
                "& input": {
                  paddingLeft: (theme) => theme.spacing(1.5),
                  color: "#002C54",
                },
                "& fieldset": {
                  paddingLeft: (theme) => theme.spacing(1.5),
                  borderColor: "#DEE1E3", // Default border color
                  // background: "white",
                },
                "& .MuiOutlinedInput-root": {
                  height: 100,
                  "& fieldset": {
                    transition: "border-color 0.3s",
                    borderRadius: "0px",
                  },
                  "&:hover fieldset": {
                    borderColor: "#DEE1E3",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#DEE1E3",
                  },
                },
              }}
              label="Message"
              id="fullWidth"
              variant="outlined"
            />
          </div>
        </div>
      </div>
      <div className="mt-[60px]">
        <PrimaryButtons label="Submit" />
      </div>
    </div>
  );
}

export default ApplyPosition;
