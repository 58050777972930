import React from "react";
import Header from "../Header";
import HeaderImage from "../../assets/HeadersBackground/AboutUs.png";

function AboutUsHeader() {
  const title = "Excelling with Precision, Ensuring Reliability";
  const description = `At PA Global, we excel with precision and reliability, setting new standards and delivering high-quality services.`;

  return (
    <div>
      <Header
        title={title}
        image={HeaderImage}
        description={description}
        textWhite={true}
        bigTitle={true}
      />
    </div>
  );
}

export default AboutUsHeader;
