import React from "react";
import Title from "../Title";
import { Accordion, AccordionItem } from "@nextui-org/react";
import BorderedArrow from "../BorderedArrow";

function Vision() {
  const defaultContent =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";

  return (
    <div className="px-[10px] pb-[64px] lg:px-5 xl:px-32 2xl:px-60 3xl:px-[372px] mt-[-7px]">
      <Title title={"Vision"} />

      <div className="mt-[2px]">
        {" "}
        <Accordion
        // motionProps={{
        //   variants: {
        //     enter: {
        //       y: 0,
        //       opacity: 1,
        //       height: "auto",
        //       transition: {
        //         height: {
        //           type: "spring",
        //           stiffness: 500,
        //           damping: 30,
        //           duration: 1,
        //         },
        //         opacity: {
        //           easings: "ease",
        //           duration: 1,
        //         },
        //       },
        //     },
        //     exit: {
        //       y: -10,
        //       opacity: 0,
        //       height: 0,
        //       transition: {
        //         height: {
        //           easings: "ease",
        //           duration: 0.25,
        //         },
        //         opacity: {
        //           easings: "ease",
        //           duration: 0.3,
        //         },
        //       },
        //     },
        //   },
        // }}
        >
          <AccordionItem
            key="1"
            aria-label="Accordion 1"
            title="To Be a Reliable and Trustworthy Partner"
            className="accordionItem"
            indicator={(props) => (
              <div className={`  ${props.isOpen ? "rotate-0" : "rotate-90"}`}>
                <BorderedArrow />
              </div>
            )}
            classNames={{
              title: "container-title mt-[1px]",
              content: "pb-[14px] pt-[2px]",
              trigger: "py-[20px]"
            }}
          >
            Our primary goal is to be a reliable and trustworthy partner in the
            professional services realm. We strive to build and maintain strong
            relationships with our clients by consistently delivering
            high-quality services and ensuring their needs are met with the
            utmost integrity and professionalism.
          </AccordionItem>
          <AccordionItem
            key="2"
            aria-label="Accordion 2"
            title="To Enhance the Profession"
            className="accordionItem"
            indicator={(props) => (
              <div className={`  ${props.isOpen ? "rotate-0" : "rotate-90"}`}>
                <BorderedArrow />
              </div>
            )}
            classNames={{
              title: "container-title",
              content: "pb-[14px] pt-[2px]",
              
            }}
          >
            Our primary goal is to be a reliable and trustworthy partner in the
            professional services realm. We strive to build and maintain strong
            relationships with our clients by consistently delivering
            high-quality services and ensuring their needs are met with the
            utmost integrity and professionalism.
          </AccordionItem>
          <AccordionItem
            key="3"
            aria-label="Accordion 3"
            className="accordionItem"
            title="To Contribute to National Economies and Address Emerging Risks"
            indicator={(props) => (
              <div className={`  ${props.isOpen ? "rotate-0" : "rotate-90"}`}>
                <BorderedArrow />
              </div>
            )}
            classNames={{
              title: "container-title",
              content: "pb-[14px] pt-[2px]",
            }}
          >
            Our primary goal is to be a reliable and trustworthy partner in the
            professional services realm. We strive to build and maintain strong
            relationships with our clients by consistently delivering
            high-quality services and ensuring their needs are met with the
            utmost integrity and professionalism.
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}

export default Vision;
